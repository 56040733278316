import React, { Component } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import logo from '../../assets/images/logo-starship.png'
import { SearchBar } from './search'
import { SearchHelper } from '../../helpers'
import { SECTIONS } from './../../utils/url'

export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            isSearchActive: false,
        }
    }

    openSearch = () => this.setState({ isSearchActive: true })
    closeSearch = () => this.setState({ isSearchActive: false })

    componentDidMount() {
        SearchHelper.saveUTMParams(window.location.search)
    }

    render() {
        const { openClass, activeSection, inferSearchSection } = this.props
        const { showMenu, isSearchActive } = this.state

        // Get current section name...
        const SECTIONS_ARRAY = Object.values(SECTIONS)
        const section = SECTIONS_ARRAY.find((section) => section.key === activeSection)
        const sectionName = section && section.name
        const bookmarks = (typeof window !== 'undefined' && JSON.parse(localStorage.getItem('bookmarks'))) || [];

        return (
            <header className={classNames('app-header', { [openClass]: openClass })}>
                <div className="heading">
                    <Link to="/" id="logo">
                        <img src={logo} alt="Starship Logo" title="Starship Logo" />
                    </Link>

                    <div className="header-links">
                        <Link to="/health-professionals">CLINICAL GUIDELINES</Link>
                        <Link to="/health-professionals/work-at-starship">WORK AT STARSHIP</Link>
                        <Link to="/contact-us">CONTACTS</Link>
                        {SECTIONS.HEALTH_PROFESSIONALS.key === activeSection && <Link className={bookmarks.length > 0 ? 'shortlist-not-empty' : 'shortlist-empty'} to="/guidelines/my-shortlist">MY SHORTLIST {bookmarks.length > 0 && <i className="fas fa-bookmark" />} </Link>}
                    </div>

                    <div id="header-searchbar-wrapper" className={isSearchActive ? 'active' : ''}>
                        <SearchBar id={'header-searchbar'} activeSection={inferSearchSection && activeSection} openSearch={() => this.openSearch()} closeSearch={() => this.closeSearch()} />
                    </div>
                    { SECTIONS.HEALTH_PROFESSIONALS.key === activeSection && <Link to="/guidelines/my-shortlist" className="shortlist-btn">
                        <i className="fas fa-star icon" />
                    </Link> }
                    
                    <a href='https://starship-foundation-donate.raisely.com/' rel='noopener noreferrer' target='_blank' className="donate-btn">
                        <i className="far fa-heart icon" />
                        <span>Donate</span>
                    </a>

                    <Link to="/contact-us" className={SECTIONS.HEALTH_PROFESSIONALS.key === activeSection ? `contact-btn contact-btn-shift` : `contact-btn`}>
                        <i className="fas fa-info icon" />
                    </Link>
                </div>
                <div className={'navbar-wrapper'}>
                    <div className={'navbar'}>
                        <div className="nav-active-menu-item" onClick={() => this.toggleMenu()}>
                            {sectionName} <i className="far fa-angle-down" />
                        </div>
                        <ul className={`nav-menu ${showMenu ? 'active' : ''}`}>
                            <li className={classNames({ highlight: activeSection === SECTIONS.VISITOR.key })}>
                                <Link to={SECTIONS.VISITOR.url}>
                                    {SECTIONS.VISITOR.name} <i className="far fa-angle-right" />
                                </Link>
                            </li>
                            <li className={classNames({ highlight: activeSection === SECTIONS.HEALTH_PROFESSIONALS.key })}>
                                <Link to={SECTIONS.HEALTH_PROFESSIONALS.url}>
                                    {SECTIONS.HEALTH_PROFESSIONALS.name} <i className="far fa-angle-right" />
                                </Link>
                            </li>
                            <li className={classNames({ highlight: activeSection === SECTIONS.SUPPORT_STARSHIP.key })}>
                                <Link to={SECTIONS.SUPPORT_STARSHIP.url}>
                                    {SECTIONS.SUPPORT_STARSHIP.name} <i className="far fa-angle-right" />
                                </Link>
                            </li>
                            <li className={classNames({ highlight: activeSection === SECTIONS.SAFEKIDS.key })}>
                                <Link to={SECTIONS.SAFEKIDS.url}>
                                    {SECTIONS.SAFEKIDS.name} <i className="far fa-angle-right" />
                                </Link>
                            </li>
                            <li className={classNames({ highlight: activeSection === SECTIONS.POWER_TO_PROTECT.key })}>
                                <Link to={SECTIONS.POWER_TO_PROTECT.url}>
                                    {SECTIONS.POWER_TO_PROTECT.name} <i className="far fa-angle-right" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        )
    }

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu,
        })
    }
}

Header.defaultProps = {
    inferSearchSection: true,
}
