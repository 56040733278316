export const serializeSearchState = (searchState) => {
    const href = []

    if(searchState.query) {
        href.push(`k=${searchState.query}`)
    }

    if(searchState.page) {
        href.push(`p=${searchState.page}`)
    }

    if(searchState.menu) {
        if(searchState.menu.categories) {
            href.push(`m_c=${searchState.menu.categories}`)
        }
        if(searchState.menu.guidelineType) {
            href.push(`m_t=${searchState.menu.guidelineType}`)
        }
        if(searchState.menu.userSection) {
            href.push(`m_us=${searchState.menu.userSection}`)
        }
    }

    return href.length > 0 ? `?${href.join('&')}` : ''
}

export const deserializeSearchState = (obj) => {
    const { k, p, m_c, m_t, m_us } = obj

    const searchState = {
        query: k || '',
        page: p || ''
    }

    if(m_c || m_t || m_us) {
        searchState.menu = {}
        if(m_c) {
            searchState.menu.categories = m_c
        }

        if(m_t) {
            searchState.menu.guidelineType = m_t
        }

        if(m_us) {
            searchState.menu.userSection = m_us
        }
    }

    return searchState
}

export const queryParamsToObject = (search) => {
    return search.replace(/(^\?)/,'').split("&").reduce((params, param) => {
        const [label, value] = param.split('=');
        
        params[decodeURIComponent(label)] = decodeURIComponent(value)
        return params
    }, {})
}

/**
 * This method saves in sessionStorage the "utm_" variables from the window.location.search passed to it.
 * @param {window.location.search} search 
 */
export const saveUTMParams = (search) => {
    const queryParams = queryParamsToObject(search)

    // TODO: This should save one object as a single entry.
    if( queryParams.utm_source)
        sessionStorage.setItem('utm_source', queryParams.utm_source)
    if( queryParams.utm_medium)
        sessionStorage.setItem('utm_medium', queryParams.utm_medium)
    if( queryParams.utm_campaign)
        sessionStorage.setItem('utm_campaign', queryParams.utm_campaign)

    return queryParams
}

/**
 * This method returns the "utm_" variables saved in this session.
 * @returns object with the "utm_" variables saved in sessionStorage.
 */
export const getUTMdata = () => {
    return {
        utm_source: sessionStorage.getItem('utm_source') || '',
        utm_medium: sessionStorage.getItem('utm_medium') || '',
        utm_campaign: sessionStorage.getItem('utm_campaign') || '',
    }
}