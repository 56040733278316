import React, {Component} from 'react'
import {
    connectPagination,
} from 'react-instantsearch-dom'
import isNaN from 'lodash/isNaN'

class Pagination extends Component {
    render() {
        const {
            canRefine,
            currentRefinement,
            nbPages,
            size
        } = this.props
        const page = isNaN(currentRefinement) ? 1 : currentRefinement
        const pages = nbPages

        const halfSize = Math.floor(size/2);

        let minPageIndex = 1
        let maxPageIndex = pages

        if(pages > size) {
            if(pages - page <= halfSize) {
                minPageIndex = pages-size+1
            } else if(page - 1 <= halfSize) {
                maxPageIndex = size
            } else {
                minPageIndex = page - halfSize
                maxPageIndex = page + halfSize
            }
        }

        return canRefine && (
            <ul className="pagination">
                <li onClick={e => this.handlePagination(page-1, pages)} className={`${page === 1 ? 'disabled': ''} prev`} >
                    <span aria-hidden="true">&lt; Prev</span>
                </li>
                {this.renderPageIndices(page, minPageIndex, maxPageIndex)}
                <li onClick={e => this.handlePagination(page+1, pages)} className={`${page === pages ? 'disabled': ''} next`} >
                    <span aria-hidden="true">Next &gt;</span>
                </li>
            </ul>
        );
    }

    renderPageIndices(page, minPageIndex, maxPageIndex) {
        const pageIndices = []
        for(let i = minPageIndex; i <= maxPageIndex; i++) {
            pageIndices.push(
                <li key={i} className={i === page ? 'active' : ''} onClick={e => this.handlePagination(i, maxPageIndex)}>{i}</li>
            )
        }

        return pageIndices
    }

    handlePagination(nextPage, pages) {
        const { refine } = this.props;

        if(nextPage < 1 || nextPage > pages) return

        refine(nextPage)
    }
}

export default connectPagination(Pagination)