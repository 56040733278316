import React, { PureComponent } from 'react'
import { withOverlayContext } from '../../contexts'

class Overlay extends PureComponent {
    render() {
        const { isActive } = this.props.overlayContext

        return <div className={`overlay ${isActive ? 'active' : ''}`} />
    }
}

export default withOverlayContext(Overlay)