import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withPopupContext } from './../../contexts'

class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
        this.inner = React.createRef();
    }

    componentDidMount() {
        document.body.classList.add("no-scroll")
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 200)
    }

    componentWillUnmount() {
        document.body.classList.remove("no-scroll")
    }

    onBackgroundClick = (e) => {
        if(this.inner.current.contains(e.target)) {
            return;
        }

        this.onClose();
    }

    onClose = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                isLoading: true
            }, () => {
                setTimeout(() => {
                    const doOnClose = this.props.onClose || this.props.popupContext.closePopup;
                    doOnClose()
                    resolve()
                }, 200)
            })
        });
    }

    render() {
        const { id, title, children, className } = this.props

        return (
            <div id={id || ''} className={['modal', !this.state.isLoading && 'loaded', className].filter(Boolean).join(' ')} onClick={this.onBackgroundClick}>
                <div className="inner" ref={this.inner}>
                    <div className="close" onClick={this.onClose}>
                        <i className="far fa-times icon" />
                    </div>
                    <div className="title">{title}</div>
                    <div className="modal-content active">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

Popup.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClose: PropTypes.func, // Function to call when modal closes. Defaulst to this.props.popupContext.closePopup()
}

export default withPopupContext(Popup)