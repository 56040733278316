import React, { Component } from 'react'
import '../../assets/styles/widgets/_imageSmartWidget.scss'

class ImageSmartWidget extends Component {
    render() {
        const imgfile  = "https:" + this.props.data.file.url;
        const width = this.props.data.width + '%'
        const wrapClass = !this.props.data.wrapText ? 'no-wrap' : ''
        const img = <img onContextMenu={(e)=> e.preventDefault()} title={`${this.props.data.title}`} src={`${imgfile}`} className={`image-align-${this.props.data.position} ${wrapClass}`} style={{ width: width}}/>
        return (
            this.props.data.enableHyperlink ? 
            (<a href={`${this.props.data.hyperlink}`} target="_blank" className={`entry-link ${wrapClass}`}> {img} </a>)
            : img
        )
    }
}
export default ImageSmartWidget;