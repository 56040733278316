import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { Link } from 'react-scroll'
import { isObject } from 'lodash'
import { withGuidelineContext, withScrollContext, withPopupContext } from '../../contexts';
import richTextOptions from '../../utils/richTextReactRendering'
function MobileHeader(props) {
    const { page, preview, offset} = props.propParent;
    const {
        className,
        trim,
    } = props
    const body = !preview ? JSON.parse(page.richText.childContentfulRichText.internal.content) : page.richText
    const content = documentToReactComponents(body, richTextOptions);
    const indexes = filter(content, c => c.type === 'h3');

    return (
        <ul className={className}>
        {map(indexes, (index, i) => {
            const heading = index.props.children
            return (
                <li key={index.props.id} className="item" title={index.text}>
                    <Link activeClass={'active'} to={index.props.id} spy={true} smooth={true} offset={offset} onClick={props.popupContext.closePopup}  >
                        {map(heading, (h, j) => {
                            if (isObject(h)) {
                                return <p className='line'>{h.props.children}</p>
                            }
                            return <p className='line'>{h}</p>
                        })}
                    </Link>
                </li>
            );
        })}
    </ul>
    )
}

export default withPopupContext(withScrollContext((MobileHeader)));