import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import map from 'lodash/map'

const ServiceProviderList = props => (
    <ul className={'service-providers'}>
        {map(props.serviceProviders, ({ node }) => {
            const isSelectedProvider = props.selectedProvider && props.selectedProvider['slug'] === node.slug
            const style = {
                backgroundColor: isSelectedProvider ? node.colour : 'inherit',
                color: isSelectedProvider ? 'white' : 'inherit',
            }

            return (
                <li key={node.slug} style={style}>
                    <Link to={`/guidelines/browse?sp=${node.slug}`} state={{provider: node.slug}} style={isSelectedProvider ? { color: 'white' } : {}}>
                        {node.name}
                    </Link>
                </li>
            )
        })}
    </ul>
)

export default props => (
    <StaticQuery
        query={graphql`
            {
                allContentfulTypeGuidelineProvider {
                    edges {
                        node {
                            name
                            slug
                            colour
                        }
                    }
                }
            }
        `}
        render={data => {
            const serviceProviders = data.allContentfulTypeGuidelineProvider.edges

            return <ServiceProviderList serviceProviders={serviceProviders} {...props} />
        }}
    />
)

ServiceProviderList.defaultProps = {
    selectedProvider: {
        colour: '',
        slug: null,
    },
}
