/**
 * Client wants assets's urls to be shorter than the ones Contentful is returning. https://propellerheadnz.atlassian.net/browse/STAR-291 "URLs for media assets to be normalised".
 * This service will call an endpoint to get a map of all assets urls and expose a method to get the short equivalent of an asset url.
 */
const axios = require('axios');
const fs = require('fs');

let assetsMap;

function init() {
    return axios.get(process.env.GATSBY_ASSETS_API_URL, {
        headers: { 'key' : process.env.GATSBY_ASSETS_API_TOKEN }
    })
    .then(response => response.data)
    .then(data => {
        fs.writeFile('./src/assets/assets.json', JSON.stringify(data), (err) => {
            console.error('error', err)
        })
        assetsMap = data;
    })
    .catch(err => {
        console.error(err);
    })
}
module.exports.init = init;

const ASSETS_URL_REGEX = /^\/\/assets\.ctfassets\.net\/.*/
const getShortURL = (assetId, assetURL) => {
    if (!assetsMap || !assetId) {
        return assetURL;
    }
    if (assetsMap[assetId]) {
        return assetsMap[assetId];
    }

    return assetURL;
}

module.exports.getShortURL = getShortURL;