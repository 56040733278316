import React from 'react'
import { Highlight } from 'react-instantsearch-dom'
import { buildURL, CONTENTFUL_GRAPHQL_TYPES } from '../../../utils/url'
import EventTracker from './../../../helpers/EventTracker'

export default ({hit}) => {
    const href = buildURL(hit, CONTENTFUL_GRAPHQL_TYPES.CALCULATOR)
    return (
        <a className="ais-Highlight-nonHighlighted" href={href} onClick={()=>EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.SEARCH, EventTracker.EVENT_TYPES.SEARCH, 'CALCULATOR_RESULT')}>
            <Highlight attribute="title" hit={hit}/>
        </a>
    )
}