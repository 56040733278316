import React from 'react'
import * as cheerio from 'cheerio'
import { Element } from 'react-scroll'
import map from 'lodash/map'
import isUndefined from 'lodash/isUndefined'
import { Gallery } from '../components/common'

const HELPER_ID = 'guideline-content-helper-id'
const INDEXED_SECTION = 'INDEXED_SECTION'
const UNINDEXED_SECTION = 'UNINDEXED_SECTION'

/*
 * Used to get indexes showed in the sidebar
 * All direct <div> nodes of .guideline-content are sections,
 * and we get the name of the section as index link, innerText of the h3 as link text
 */
export function getGuidelineIndexes() {
    const sections = !isUndefined(document) && document.querySelectorAll('.guideline-content > div[name]')

    return map(sections, (section) => {
        const sectionName = section.getAttribute('name')
        const sectionTitle = !isUndefined(document) && document.querySelector(`.guideline-content > div[name="${sectionName}"] > h3`).innerText

        return {
            to: sectionName,
            text: sectionTitle
        }
    })
}

/*
 * Convert richtext into Section/Gallery Components
 */
export function groupGuidelineSections(guideline) {
    const $ = cheerio.load(`<div id="${HELPER_ID}">${guideline.htmlBody}</div>`)
    const sections = []
    const galleries = []

    $(`#${HELPER_ID} > *`).each(function(i) {
        const el = $(this)

        if(sections.length < 1 || this.name === 'h3') {
            sections.push([])
        }

        if(el.attr('class') === 'gallery') {
            galleries.push(getGallery(el, $))
            el.attr('id', `gallery-${galleries.length-1}`)
        }

        sections[sections.length-1].push(el)
    });

    return {
      sections: map(sections, (section, i) => getSection(section, i, $)),
      galleries
    }
}

/*
 * Get dosage table links
 */
export function getDosageTables() {
    const dosageTables = !isUndefined(document) && document.querySelectorAll('.dosage-table')

    return map(dosageTables, (dosageTable) => {
        const tableName = dosageTable.getAttribute('data-name')
        const anchor = dosageTable.getAttribute('id')

        return {
            to: anchor,
            text: tableName
        }
    })
}

/*
 * If a section's first child is a <h3> node, it's a indexed section,
 * an index will be generated for the section and show at the sidebar
 */
function getSectionType(section) {
    if(!section[0][0]) {
        return false
    }

    return section[0][0].name === 'h3' ? INDEXED_SECTION : UNINDEXED_SECTION;
}

function getSectionId(section) {
    if(!section[0][0] || section[0][0].name !== 'h3') {
        return null
    }

    // stripe all special characters and connect words with '-'
    return !!section[0][0].firstChild && !!section[0][0].firstChild.data ? section[0][0].firstChild.data.trim().replace(/[^\w\s\d-]/gi, '').replace(/\s+/g,'-').toLowerCase() : null
}

function getSection(section, index, $) {
    const sectionType = getSectionType(section);

    switch(sectionType) {
        case INDEXED_SECTION:
            return getIndexedSection(section, index, $)
        case UNINDEXED_SECTION:
            return getUnindexedSection(section, index, $)
        default:
            return null
    }
}

function getIndexedSection(section, index, $) {
    return (
        <Element key={`section-${index}`} className={`guideline-section`} id={getSectionId(section)} name={getSectionId(section)} dangerouslySetInnerHTML={{
            __html: map(section, (p) => $.html(p)).join('')
        }} />
    )
}

function getUnindexedSection(section, index, $) {
    const name = getSectionId(section);
    return (
        <div key={`section-${index}`} id={name} dangerouslySetInnerHTML={{
            __html: map(section, (p) => $.html(p)).join('')
        }} />
    )
}

function getGallery(el, $) {
    const data = {}

    data.id = el.attr('id')
    data.title = el.children('.gallery-caption').text()
    data.position = el.attr('data-position')
    data.wrapText = el.attr('data-wrap-text')
    data.images = map(el.find('.item'), function(item) {
      const src = $(item).find('img').attr('src')
      const caption = $(item).find('.item-caption').text()
      const title = $(item).find('img').attr('title') || ''
      return {
          title: title.trim(),
          alt: caption,
          src
      }
    })

  return (
    <Gallery data={data} />
  )
}