import React, { Component } from 'react'
import Select from 'react-select'
import { navigate, Link } from 'gatsby'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import uniq from 'lodash/uniq'
import forEach from 'lodash/forEach'
import find from 'lodash/find'

const indexes = ['All','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'] // prettier-ignore

const seeAll = {
    value: 'see_all',
    label: 'See all',
}

/**
 * Component for rendering alphabetically filterable list
 */
class AZFilterList extends Component {
    constructor(props) {
        super(props)
        this.state = AZFilterList.initializeState(props)
    }

    static getDerivedStateFromProps(props) {
        return AZFilterList.initializeState(props)
    }

    static initializeState = (props) => {
        const { selectedType, selectedCategory: selectedCat, items: guidelineItems, itemKey } = props

        let availableIndexes = ['All']
        let availableTypesFilterOptions = []
        let availableCategories = []

        guidelineItems.forEach(item => {
            if (item.guidelineType) {
                const label = item.guidelineType.guidelineType
                const value = label.toLowerCase().replace(/ /g, '_')

                availableTypesFilterOptions.push({ value, label })
            }

            if(item.categories) {
                availableCategories = [...availableCategories, ...item.categories.map(category => {
                    const label = category.name
                    const value = label.toLowerCase().replace(/ /g, '_')

                    return { value, label }
                })]
            }
        })

        availableTypesFilterOptions = [seeAll, ...sortBy(uniqBy(availableTypesFilterOptions, 'value'), ['value'])]
        availableCategories = [seeAll, ...sortBy(uniqBy(availableCategories, 'value'), ['value'])]

        const items = sortBy(guidelineItems, [itemKey])

        const selectedIndex = get(props, 'selectedIndex') || 'All'
        const selectedTypeFilter = find(availableTypesFilterOptions, { value: selectedType}) || seeAll
        const selectedCategory = find(availableCategories, { value: selectedCat }) || seeAll

        const filteredItems = items.filter(item => {
            let available = true
            // check index of item
            available = available && (selectedIndex === 'All' || ((get(item, itemKey) ? get(item, itemKey).charAt(0).toUpperCase() : null) === selectedIndex))

            // check type of item
            available = available && (selectedTypeFilter.value === seeAll.value
                || (item.guidelineType && item.guidelineType.guidelineType.toLowerCase().replace(/ /g, '_') === selectedTypeFilter.value))

            // check category of item
            if(selectedCategory.value !== seeAll.value) {
                let match = false
                forEach(item.categories, category => {
                    if(category.name.toLowerCase().replace(/ /g, '_') === selectedCategory.value) {
                        match = true
                    }
                })

                available = available && match
            }

            return available
        })

        availableIndexes = [ ...availableIndexes, ...items.filter(item => {
            let available = true

            // check type of item
            available = available && (selectedTypeFilter.value === seeAll.value
                || (item.guidelineType && item.guidelineType.guidelineType.toLowerCase().replace(/ /g, '_') === selectedTypeFilter.value))

            // check category of item
            if(selectedCategory.value !== seeAll.value) {
                let match = false
                forEach(item.categories, category => {
                    if(category.name.toLowerCase().replace(/ /g, '_') === selectedCategory.value) {
                        match = true
                    }
                })

                available = available && match
            }

            return available
        }).map(item => {
            const i = get(item, itemKey)
            if(i)
            return i.charAt(0)
                .toUpperCase()
            else return ''
        })]

        return {
            availableIndexes: uniq(availableIndexes),
            items,
            filteredItems,
            selectedIndex: selectedIndex || indexes[0],
            availableTypesFilterOptions,
            selectedTypeFilter,
            availableCategories,
            selectedCategory,
        }
    }

    /**
     * Filters guidelines depending on the values supplied
     * @param selectedIndex current letter index
     * @param selectedTypeFilter current type filter
     * @param selectedCategory current category
     */
    filterGuidelines(selectedIndex, selectedTypeFilter=seeAll, selectedCategory=seeAll) {
        const { selectedServiceProvider } = this.props
        navigate(`${window.location.pathname}?${!!selectedServiceProvider ? `sp=${selectedServiceProvider}&` : ''}index=${selectedIndex}&type=${selectedTypeFilter.value}&cat=${selectedCategory.value}`)
    }

    handleTypeFilterChange = selectedTypeFilter => {
        this.filterGuidelines(this.state.selectedIndex, selectedTypeFilter, this.state.selectedCategory)
    }

    handleCategoryChange = selectedCategory => {
        this.filterGuidelines(this.state.selectedIndex, this.state.selectedTypeFilter, selectedCategory)
    }

    render() {
        const { itemKey, renderComponent, showTypeFilter, onItemHover, onItemLeave, emptyTextComponent } = this.props
        const {
            availableIndexes,
            filteredItems,
            selectedIndex,
            availableTypesFilterOptions,
            selectedTypeFilter,
            availableCategories,
            selectedCategory
        } = this.state
        let currentIndex = null

        return (
            <div className="az-filter-list">
                <div className="header row">
                    {showTypeFilter && (
                        <div className="filter-control col-sm-12 row order-md-1 order-2">
                            <div className="col-sm-12 col-md-1">
                                <span className="label">Type</span>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <Select
                                    value={selectedTypeFilter}
                                    options={availableTypesFilterOptions}
                                    isSearchable={false}
                                    className="select"
                                    classNamePrefix="az-select"
                                    onChange={this.handleTypeFilterChange}
                                />
                            </div>
                            <div className="col-sm-12 col-md-1">
                                <span className="label">Category</span>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <Select
                                    value={selectedCategory}
                                    options={availableCategories}
                                    isSearchable={false}
                                    className="select"
                                    classNamePrefix="az-select"
                                    onChange={this.handleCategoryChange}
                                />
                            </div>
                        </div>
                    )}
                    <div className="filter-control col-sm-12 row order-md-12 order-1">
                        <div className="col-sm-12 col-md-1">
                            <span className="label">Alphabet</span>
                        </div>
                        <div className="col-sm-12 col-md-11">
                            <ul className="indexes">
                                {indexes.map(index => {
                                    const includesIndex = availableIndexes.includes(index)
                                    return (
                                        <li
                                            key={`index-${index}`}
                                            title={index}
                                            className={`${index === selectedIndex ? 'active' : ''} ${
                                                includesIndex ? '' : 'disabled'
                                            }`}
                                            onClick={() =>
                                                includesIndex ? this.filterGuidelines(index, selectedTypeFilter, selectedCategory) : null
                                            }
                                        >
                                            {index}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                {
                    filteredItems.length > 0 ? <ul className="guideline-list">
                        {filteredItems.map(item => {
                            const newIndex = item[itemKey] ? item[itemKey].charAt(0).toUpperCase() : ''
                            if (newIndex == ''){
                                return
                            }
                            const itemKeyValue = get(item, itemKey)
                            if (currentIndex !== newIndex) {
                                currentIndex = newIndex
                                return (
                                    <li name={`index-${currentIndex}`} key={currentIndex}>
                                        <span className="index">{currentIndex}</span>
                                        <span key={itemKeyValue} onMouseEnter={() => onItemHover(item)} onMouseLeave={() => onItemLeave(item)} className="item">
                                            {renderComponent(item)}
                                        </span>
                                    </li>
                                )
                            }
                            return (
                                <li onMouseEnter={() => onItemHover(item)} onMouseLeave={() => onItemLeave(item)} key={itemKeyValue} className="item">
                                    {renderComponent(item)}
                                </li>
                            )
                        })}
                    </ul> : emptyTextComponent ? emptyTextComponent : <p className={'no-result'}>No results. Please update your filters or <Link to={'/guidelines/browse'}>click here to browse all guidelines</Link>.</p>
                }
            </div>
        )
    }
}

/**
 * Note on renderComponent:
 * it expects a function that takes the an item and returns a React Component for that item
 * (item) => <Component withItem={item}/>
 * Note on onTypeChange:
 * It provides a callback to the parent component to signal type change. Sends the selected item value
 */
AZFilterList.propTypes = {
    items: PropTypes.array.isRequired,
    itemKey: PropTypes.string.isRequired, // can be a nested property (foo.bar)
    renderComponent: PropTypes.func.isRequired,
    showTypeFilter: PropTypes.bool, // show type combobox
}

AZFilterList.defaultProps = {
    showTypeFilter: false,
}

export default AZFilterList
