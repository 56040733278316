import React, { PureComponent } from 'react'
import {Events, animateScroll} from 'react-scroll'
import isUndefined from 'lodash/isUndefined'

const ScrollContext = React.createContext()

export default class ScrollProvider extends PureComponent {
    constructor() {
        super()
        this.state = {
            scrollTop: 0,
            isScrollingUp: false,
            isFixedIndex: false,
            isHeaderOpen: false,
            isLocked: false,
            isReadingContent: false,
            isBottomReached: false,
            offset: 0,
            setTopPos: this.setTopPos,
            setBotPos: this.setBotPos,
            scrollToTop: this.scrollToTop,
        }
        this.lastKnownScrollPosition = 0;
        this.ticking = false;
        this.contentHeight = 0;
    }

    componentDidMount() {
        this.handleScroll(); 
        this.registerPerformantScrollEvent(this.handleScroll);

        Events.scrollEvent.register('begin', (to, element) => {
            this.setState({ isLocked: true })
        });
        
        Events.scrollEvent.register('end', (to, element) => {
            setTimeout(() => {
                this.setState({ isLocked: false })
            }, 500);
        });

        setTimeout(() => {
            const contentEl = document.querySelector('.content')
            if(!contentEl) {
                return
            }
            this.contentHeight = !!contentEl ? contentEl.offsetHeight : 0
        }, 2000);
    }

    registerPerformantScrollEvent = (doOnScroll) => {
        // Code taken from https://developer.mozilla.org/en-US/docs/Web/Events/scroll#Scroll_event_throttling
        this.lastKnownScrollPosition = 0;
        this.ticking = false;

        !isUndefined(window) && window.addEventListener('scroll', (e) => {
            this.lastKnownScrollPosition = window.scrollY;

            if (!this.ticking) {
                window.requestAnimationFrame(() => {
                    doOnScroll(this.lastKnownScrollPosition);
                    this.ticking = false;
                });

                this.ticking = true;
            }
        });
    }

    handleScroll = () => {
        const scrollTop = !isUndefined(window) && window.pageYOffset
        const isReadingContent = scrollTop >= 250
        let newState = {
            isFixedIndex: scrollTop >= this.topPos,
            isBottomReached: scrollTop >= this.botPos,
            isReadingContent,
        }

        // If the header is locked we only update the Index to get fixed to the top when scrolling.
        if(this.state.isLocked) {
            newState.offset = this.getScrollOffset(this.state.isScrollingUp);
            this.setState(newState);
            return;
        }

        const isScrollingUp = this.state.scrollTop < this.contentHeight - window.innerHeight && scrollTop < this.state.scrollTop // TODO: This should use a threshhold on how much the user scroll.
        const isHeaderOpen = newState.isFixedIndex && isScrollingUp

        newState = {
            ...newState,
            scrollTop,
            isScrollingUp: isReadingContent && isScrollingUp,
            isHeaderOpen,
        }

        newState.offset = this.getScrollOffset(newState.isScrollingUp);
        this.setState(newState);
    }
    
    setTopPos = (topPos) => this.topPos = topPos
    setBotPos = (botPos) => this.botPos = botPos

    getScrollOffset = (isScrollingUp) => {
        if(!isUndefined(document) && !document.querySelector('#mobile-floating-bar #innerIndex')) return 0;

        const appHeaderHeight = !isUndefined(document) && document.querySelector('#mobile-floating-bar .app-header').offsetHeight;
        const innerIndexHeight = !isUndefined(document) && document.querySelector('#mobile-floating-bar #innerIndex').offsetHeight;
        let offset = 0;

        const isMobile = !isUndefined(window) && window.innerWidth < 980;

        if(isMobile) {
            offset = -(innerIndexHeight);
            if(isScrollingUp) {
                offset -= appHeaderHeight;
            }
        }

        return offset;
    }

    scrollToTop = () => {
        animateScroll.scrollToTop()
    }

    render = () => (
        <ScrollContext.Provider value={this.state}>
            {this.props.children}
        </ScrollContext.Provider>
    )
}

export function withScrollContext(Component) {
    return (props) =>  (
        <ScrollContext.Consumer>
            { state => <Component {...props} scroll={state} /> }
        </ScrollContext.Consumer>
    )
}