import React, { PureComponent } from 'react'
import isUndefined from 'lodash/isUndefined'
const PopupContext = React.createContext()

export default class PopupProvider extends PureComponent {
    constructor() {
        super()

        this.POPUP_ID = {
            HEALTH_PATHWAYS: 'HEALTH_PATHWAYS',
            FORMS: 'FORMS',
            TOOLS: 'TOOLS',
            INDEX: 'INDEX',
            PATIENT_INFORMATION: 'PATIENT_INFORMATION',
            DISCLAIMER: 'DISCLAIMER',
            DRUG_DOSAGE: 'DRUG_DOSAGE',
            SHARE: 'SHARE',
            CALCULATORS: 'CALCULATORS',
        }

        this.state = {
            popupShowing: null,
            closePopup: this.closePopup,
            togglePopup: this.togglePopup,
            POPUP_ID: this.POPUP_ID,
        }
    }

    handleTouch = (e) => {
        e.preventDefault();
    }

    stopBodyScrolling = (bool) => {
                let pageContent = document.getElementById("page-content")
                if(pageContent){
                 if (bool) {
                    document.getElementById("page-content").addEventListener("touchmove", this.handleTouch, false);
                    pageContent.addEventListener("touchmove", this.handleTouch, false);
                     document.body.classList.add("no-scroll")
         
                 } else {
                    document.getElementById("page-content").removeEventListener("touchmove", this.handleTouch, false);
                    pageContent.removeEventListener("touchmove", this.handleTouch, false);
                     document.body.classList.remove("no-scroll")
                 }
             }
            }
    
    closePopup = () => {
        !isUndefined(document) && document.body.classList.remove("no-scroll")
        this.setState({ popupShowing: null })

        this.stopBodyScrolling(false)
    }

    togglePopup = (POPUP_ID) => {
        this.setState({
            popupShowing: this.state.popupShowing === POPUP_ID ? null : POPUP_ID,
        })

        this.stopBodyScrolling(POPUP_ID ? true : false)
    }

    render = () => (
        <PopupContext.Provider value={this.state}>
            {this.props.children}
        </PopupContext.Provider>
    )
}

export function withPopupContext(Component) {
    return function WrapperComponent(props) {
        return (
            <PopupContext.Consumer>
                {state => <Component {...props} popupContext={state} />}
            </PopupContext.Consumer>
        );
    };
}