import React from "react"
import { Highlight } from 'react-instantsearch-dom'
import { buildURL, CONTENTFUL_GRAPHQL_TYPES } from '../../../utils/url'
import EventTracker from './../../../helpers/EventTracker'

export default ({hit}) => {
    let href = buildURL(hit, CONTENTFUL_GRAPHQL_TYPES.GUIDELINE)
    let targetAttr = ''
    const { categories } = hit
    const badges = categories.slice(0, 2)
    if(categories.length > 2) {
        badges.push('...')
    }
    if (hit.searchRedirectUrl) {
        href = hit.searchRedirectUrl
        targetAttr = '_blank'
    }

    return (
        <a className="ais-Highlight-nonHighlighted" target={targetAttr} href={href} onClick={()=>EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.SEARCH, EventTracker.EVENT_TYPES.SEARCH, 'GUIDELINE_RESULT')}>
            <div className="results">
                <Highlight attribute="title" hit={hit}/>
            </div>
            <div className="badges">
                {badges.map(badge => (
                    <div key={badge} className="badge badge-category">
                        <span>{badge}</span>
                    </div>
                ))}
            </div>
        </a>
    )
}