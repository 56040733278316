import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

export default () => (
    <StaticQuery
        query={graphql`{
            allContentfulTypeGuidelineCategory {
                edges {
                    node {
                        name
                        slug
                    }
                }
            }
        }`}
        render={data => {
            const categories = orderBy(data.allContentfulTypeGuidelineCategory.edges, e => e.node.name, ['asc'])

            return (
                <ul className={'search-categories'}>
                    { map(categories, ({node}) => <li key={ node.slug }><Link to={`/guidelines/browse/?cat=${encodeURIComponent(node.name.toLowerCase().replace(/ /g, '_'))}`}>{ node.name }</Link></li>) }
                </ul>
            )
        }}
    />
)