import React, { Component } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import EventTracker from './../../helpers/EventTracker'

class SearchBox extends Component {
    hasFocus = false

    componentDidUpdate() {
        const { isActive } = this.props
        if (isActive && document.activeElement !== this.searchBox) {
            this.searchBox.focus()
        }
    }

    render() {
        const { currentRefinement, refine } = this.props

        return (
            <div className="search-input">
                <input ref={(el) => (this.searchBox = el)} type="search" value={currentRefinement} onChange={(e) => refine(e.target.value)} placeholder="Search here..." onKeyPress={(e) => this.handleEnterOnSearchBox(e)} onFocus={(e) => this.handleFocus(e)} />
                <button type="submit" onClick={(e) => this.gotoAdvancedSearch()}>
                    <i className="far fa-search" />
                </button>
            </div>
        )
    }

    handleEnterOnSearchBox = (event) => {
        if (event.key === 'Enter') {
            this.gotoAdvancedSearch()
        }
    }

    handleBlur = (e) => {
        const { onBlur, searchBarId } = this.props

        if (e.target.closest(`div#${searchBarId}`) === null) {
            this.hasFocus = false
            document.removeEventListener('mousedown', this.handleBlur)
            onBlur()
        }
    }

    handleFocus = () => {
        const { onFocus } = this.props
        EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.SEARCH, EventTracker.EVENT_TYPES.SEARCH, 'START')

        this.hasFocus = true
        document.addEventListener('mousedown', this.handleBlur)
        onFocus()
    }

    gotoAdvancedSearch = () => {
        const { currentRefinement, activeSection, isPowerToProtect } = this.props
        EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.SEARCH, EventTracker.EVENT_TYPES.SEARCH, 'ADVANCED_SEARCH')
        window.location.href = isPowerToProtect ? `/power-to-protect/search?k=${currentRefinement}${activeSection ? `&m_us=${activeSection}` : ''}` : `/search?k=${currentRefinement}${activeSection ? `&m_us=${activeSection}` : ''}`
    }
}

export default connectSearchBox(SearchBox)
