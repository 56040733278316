import React, { Component } from 'react'
import * as contentful from 'contentful'
import { PreviewHelper, SearchHelper } from '../../helpers/index'
import { map } from 'lodash'

const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
    accessToken: process.env.GATSBY_CONTENTFUL_PREVIEW_TOKEN,
    environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
    host: 'preview.contentful.com',
})
const powerToProtectClient = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_PTP_SPACE_ID, // Identifiants pour "Power to Protect"
    accessToken: process.env.GATSBY_CONTENTFUL_PTP_PREVIEW_TOKEN,
    environment: process.env.GATSBY_CONTENTFUL_PTP_ENVIRONMENT,
    host: 'preview.contentful.com',
})

export default function withPreview(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props)
            this.state = {
                loading: true,
            }
            this.getEntry = this.getEntry.bind(this)
            this.getPowerToProtectEntry = this.getPowerToProtectEntry.bind(this)
        }
        getEntry(contentType, params) {
            const value = SearchHelper.queryParamsToObject(params)
            const slug = value.slug
            const query = {
                content_type: contentType,
                'fields.slug': slug,
                locale: 'en-NZ',
                include: 5,
            }

            if (value.id) {
                return client.getEntry(value.id).then((entry) => {
                    this.setState({
                        loading: false,
                    })
                    return entry
                })
            }

            return client.getEntries(query).then(
                (entry) => {
                    this.setState({
                        loading: false,
                    })
                    return entry.items[0]
                },
                () => {
                    this.setState({
                        loading: false,
                    })
                    return null
                }
            )
        }
        getPowerToProtectEntry(contentType, params) {
            const value = SearchHelper.queryParamsToObject(params)
            const slug = value.slug
            const query = {
                content_type: contentType,
                'fields.slug': slug,
                locale: 'en-NZ',
                include: 5,
            }

            if (value.id) {
                return powerToProtectClient.getEntry(value.id).then((entry) => {
                    this.setState({
                        loading: false,
                    })
                    return entry
                })
            }

            return powerToProtectClient.getEntries(query).then(
                (entry) => {
                    this.setState({
                        loading: false,
                    })
                    return entry.items[0]
                },
                () => {
                    this.setState({
                        loading: false,
                    })
                    return null
                }
            )
        }

        getSitemap() {
            const query = {
                content_type: 'widgetSitemap',
                locale: 'en-NZ',
            }

            return client.getEntries(query).then(
                (sitemap) => {
                    return sitemap.items.map((item) => {
                        return {
                            ...item.fields,
                            links: PreviewHelper.parseLinkList(item.fields.links),
                        }
                    })
                },
                () => []
            )
        }
        getPowerToProtectSitemap() {
            const query = {
                content_type: 'widgetSitemapPTP',
                locale: 'en-NZ',
            }

            return powerToProtectClient.getEntries(query).then(
                (sitemap) => {
                    return sitemap.items.map((item) => {
                        return {
                            ...item.fields,
                            links: PreviewHelper.parseLinkList(item.fields.links),
                        }
                    })
                },
                () => {
                    console.error('Error fetching Power to Protect sitemap')
                    return []
                }
            )
        }

        getSiteInfo() {
            return {
                title: 'Starship',
                author: 'Starship',
                description: 'Starship Child Health is a dedicated paediatric healthcare service and major teaching centre, providing family centred care to children and young people throughout New Zealand and the South Pacific. Starship Foundation is a social-profit organisation that raises funds so Starship Child Health can better care for its young patients.',
                siteUrl: process.env.SITE_URL,
                twitterHandle: 'captainstarship',
            }
        }

        render() {
            return <WrappedComponent getPowerToProtectSitemap={this.getPowerToProtectSitemap} loading={this.state.loading} getEntry={this.getEntry} getPowerToProtectEntry={this.getPowerToProtectEntry} getSitemap={this.getSitemap} getSiteInfo={this.getSiteInfo} {...this.props} />
        }
    }
}
