import React, { Component } from 'react'
import map from 'lodash/map'
import Slider from 'react-slick'

export default class Gallery extends Component {

  state = {
    isActive: false,
    settings: {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: <button className="no-style slick-prev"><i className="far fa-angle-left icon" /></button>,
      nextArrow: <button className="no-style slick-next"><i className="far fa-angle-right icon" /></button>
    }
  }

    toggleGallery = () => {
        this.setState({
            isActive: !this.state.isActive
        })
        if (this.state.isActive) {
            document.body.classList.remove("no-scroll")
        } else {
            document.body.classList.add("no-scroll")
        }
    }

    render() {
        const { data } = this.props
        const { isActive, settings } = this.state
        const wrapClass = (String(data.wrapText) === 'false') ? 'no-wrap' : ''
        const position = data.position ? data.position.toLowerCase() : ''
        return (
          <div id={data.id} className={`gallery-wrapper ${position} ${wrapClass}`}>
                <div role="img" aria-label={JSON.stringify(data.images[0].title)} onClick={this.toggleGallery} className="gallery-box" style={{
                  backgroundImage: `url(${data.images[0].src})`
                }}>
                    <span>{data.title}</span>
                    <div className="images" onClick={this.toggleGallery}><i className="far fa-images icon" /></div>
                    <div className="gradient" />
                </div>

                {isActive && (
                    <div className="image-gallery">
                        <div className={`overlay${isActive ? ' active' : null}`} />
                        <button className="no-style close" onClick={this.toggleGallery}><i className="far fa-times icon" /></button>
                        <Slider {...settings} >
                            {map(data.images, (img, i) => (
                                    <div className="slide-item" key={`slide-${i}`}>
                                        <div className="inner">
                                            <img onContextMenu={(e)=> e.preventDefault()} src={img.src} alt={img.alt} title={img.title} />
                                            <div className="description">{img.alt}</div>
                                        </div>
                                    </div>
                                )
                            )}
                        </Slider>
                    </div>
                )}
            </div>
        )
    }
}