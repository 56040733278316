import React from "react"
import { Highlight } from 'react-instantsearch-dom'
import { buildURL, JSON_ENTRY_TYPES } from '../../../utils/url'
import EventTracker from './../../../helpers/EventTracker'

export default ({hit}) => {
    const href = buildURL(hit, JSON_ENTRY_TYPES.SERVICE)
    const { categories } = hit
    const badges = categories.slice(0, 2)
    if(categories.length > 2) {
        badges.push('...')
    }

    return (
        <a className="ais-Highlight-nonHighlighted" href={href} onClick={()=>EventTracker.customEvent(EventTracker.EVENT_CATEGORIES.SEARCH, EventTracker.EVENT_TYPES.SEARCH, 'HEALTHPOINT_RESULT')}>
            <div className="results">
                <Highlight attribute="title" hit={hit}/>
            </div>
            <div className="badges">
                {badges.map(badge => (
                    <div key={badge} className="badge badge-category">
                        <span>{badge}</span>
                    </div>
                ))}
            </div>
        </a>
    )
}