const has = require('lodash/has')
const get = require('lodash/get')
const includes = require('lodash/includes')
const { navigate } = require('gatsby')

const SECTIONS = {
    VISITOR: { key: 'Visitor', name: 'Patient, Parent or Visitor', url: '/' },
    HEALTH_PROFESSIONALS: { key: 'Health Professional', name: 'Health Professionals', url: '/health-professionals' },
    GUIDELINES: { key: 'Health Professional', name: 'Health Professionals', url: '/guidelines' },
    SUPPORT_STARSHIP: { key: 'Supporter', name: 'Support Starship', url: '/foundation' },
    SAFEKIDS: { key: 'Safekids', name: 'Safekids Aotearoa', url: '/safekids' },
    HEALTH_SERVICES: { key: 'Health services', name: 'Awhi Tautoko  <br> Help lines, supports, services', url: '/power-to-protect/health-services' },
    PACIFIC_VILLAGE: { key: 'Pacific village', name: 'Pacific village', url: '/power-to-protect/pacific-village' },
    WHANAU_MAORI: { key: 'Whānau māori', name: 'Whānau Māori', url: '/power-to-protect/whanau-maori' },
    HEALTH_PROFESSIONALS_PTP: { key: 'Health professionals', name: 'Kaimahi Hauora <br> Health Professionals', url: '/power-to-protect/health-professionals' },
    ABOUT_US: { key: 'About us', name: 'Te Rōpū P2P <br> About us', url: '/power-to-protect/about-us' },
    POWER_TO_PROTECT: { key: 'Power to protect', name: 'Power To Protect', url: '/power-to-protect' },
}
const PTPSECTIONS = {
    VISITOR: { key: 'Visitor', name: 'Kimihia he ratonga hauora <br> Find health services', url: '/' },
    PACIFICVILLAGE: { key: 'Pacific village', name: 'Pacific Village', url: '/power-to-protect/pacific-village' },
    GUIDELINES: { key: 'Health Professional', name: 'Whānau Māori', url: '/guidelines' },
    SUPPORT_STARSHIP: { key: 'Supporter', name: 'Kaimahi Hauora <br> Health Professionals', url: '/foundation' },
    SAFEKIDS: { key: 'Safekids', name: 'Te Rōpū P2P <br> About us ', url: '/safekids' },
}
module.exports.SECTIONS = SECTIONS
module.exports.PTPSECTIONS = PTPSECTIONS

const SECTIONS_ARRAY = Object.values(SECTIONS)
module.exports.SECTIONS_ARRAY = SECTIONS_ARRAY

// The contentful types for querying data through the content delivery API
const CONTENTFUL_ENTRY_TYPES = {
    GENERIC_PAGE: 'genericPage',
    GENERIC_PAGE_PTP: 'pageGenericPTP',
    WIDGETS_PAGE: 'widgetsPage',
    WIDGETS_PAGE_PTP: 'pageWidgetsPTP',
    PAGES_GROUP: 'pagesGroup',
    PAGES_GROUP_PTP: 'pageGroupPTP',
    DIRECTORY_SERVICES_PTP: 'pageDirectoryOfServicesPTP',
    STORY_PAGE: 'storyPage',
    GUIDELINE: 'guideline',
    CALCULATOR: 'calculator',
}
module.exports.CONTENTFUL_ENTRY_TYPES = CONTENTFUL_ENTRY_TYPES

// The json types for querying data from gatsby graphql
const JSON_ENTRY_TYPES = {
    SERVICE: 'service',
}
module.exports.JSON_ENTRY_TYPES = JSON_ENTRY_TYPES

// List of types that are gonna be shown in the pagesGroup widget and the pagesGroupIndex.
const pagesGroupChildTypes = [CONTENTFUL_ENTRY_TYPES.GENERIC_PAGE, CONTENTFUL_ENTRY_TYPES.GENERIC_PAGE_PTP, CONTENTFUL_ENTRY_TYPES.WIDGETS_PAGE, CONTENTFUL_ENTRY_TYPES.WIDGETS_PAGE_PTP, CONTENTFUL_ENTRY_TYPES.PAGES_GROUP, CONTENTFUL_ENTRY_TYPES.PAGES_GROUP_PTP, CONTENTFUL_ENTRY_TYPES.DIRECTORY_SERVICES_PTP, CONTENTFUL_ENTRY_TYPES.STORY_PAGE]
module.exports.pagesGroupChildTypes = pagesGroupChildTypes

// The contentful types for querying data through grapql
const CONTENTFUL_GRAPHQL_TYPES = {
    GENERIC_PAGE: 'ContentfulPageGeneric',
    WIDGETS_PAGE: 'ContentfulPageWidgets',
    PAGES_GROUP: 'ContentfulPageGroup',
    GENERIC_PAGE_PTP: 'ContentfulPageGenericPtp',
    WIDGETS_PAGE_PTP: 'ContentfulPageWidgetsPtp',
    PAGES_GROUP_PTP: 'ContentfulPageGroupPtp',
    GUIDELINE: 'ContentfulPageGuideline',
    STORY_PAGE: 'ContentfulPageStory',
    LINK: 'ContentfulWidgetLink',
    LINK_PTP: 'ContentfulWidgetLinkPtp',
    CALCULATOR: 'ContentfulWidgetCalculator',
    DIRECTORY_SERVICES: 'ContentfulPageDirectoryOfServices',
    DIRECTORY_SERVICES_PTP: 'ContentfulPageDirectoryOfServicesPtp',
    CUSTOM_PAGE: 'ContentfulPageCustom',
    DONATION_PAGE: 'ContentfulPageDonation',
}
const LAYOUTS = {
    STORY: 'Story',
    GENERIC: 'Generic',
}
module.exports.CONTENTFUL_GRAPHQL_TYPES = CONTENTFUL_GRAPHQL_TYPES

const isPageType = (type) => {
    const { GENERIC_PAGE, WIDGETS_PAGE, GENERIC_PAGE_PTP, WIDGETS_PAGE_PTP, DIRECTORY_SERVICES_PTP, PAGES_GROUP_PTP } = CONTENTFUL_GRAPHQL_TYPES
    return type === GENERIC_PAGE || type === WIDGETS_PAGE || type === GENERIC_PAGE_PTP || type === WIDGETS_PAGE_PTP || type === DIRECTORY_SERVICES_PTP || type === PAGES_GROUP_PTP
}
module.exports.isPageType = isPageType

/**
 * This method receives an item and returns it's CONTENTFUL_GRAPHQL_TYPES.
 * @param {Object} item - Could be an item retrieved from a graphql query or from the content delivery API.
 * @returns {CONTENTFUL_GRAPHQL_TYPES} One of the CONTENTFUL_GRAPHQL_TYPES property or undefined
 */
const getGraphqlType = (item) => {
    // Graphql item
    if (has(item, '__typename')) {
        return item.__typename
    }

    // content delivery API item
    const contentTypeId = get(item, 'sys.contentType.sys.id')
    if (contentTypeId) {
        const contentfulEntryTypeKey = Object.keys(CONTENTFUL_ENTRY_TYPES).find((key) => CONTENTFUL_ENTRY_TYPES[key] === contentTypeId)
        return CONTENTFUL_GRAPHQL_TYPES[contentfulEntryTypeKey]
    }
}

const getUserSectionUrl = (item) => {
    const section = item && SECTIONS_ARRAY.find((section) => section.key === item.userSection)
    const sectionURL = get(section, 'url', '')
    return sectionURL === '/' ? '' : sectionURL
}

/**
 * This methods build the URL for an item depending on his type (or a type can be forced).
 * @param {Object} item - Could be an item retrieved from a graphql query or from the content delivery API.
 * @param {CONTENTFUL_GRAPHQL_TYPES} forcedType - Optional field to build a URL for a specific type.
 */

const locale = 'en-NZ'

function getField(node, fieldPath) {
    const field = get(node, fieldPath)
    return get(field, locale, field)
}

const buildURL = (item, forcedType = null) => {
    const slug = (item && item.slug) || getField(item, 'fields.slug')
    const type = forcedType || getGraphqlType(item)
    const section = getUserSectionUrl(item)
    const itemLayout = (item && item.layout) || getField(item, 'fields.layout')

    if (isPageType(type)) {
        const layout = itemLayout ? itemLayout.layout : ''
        if (layout === LAYOUTS.STORY) {
            return `${SECTIONS.SUPPORT_STARSHIP.url}/story/${slug}`
        }

        if (section.includes('power-to-protect')) {
            return includes(['power-to-protect', 'health-services', 'pacific-village', 'whanau-maori', 'health-professionals', 'about-us'], slug) ? section : `${section}/${slug}`
        }

        return section === `/${slug}` // is homepage
            ? section
            : `${section}/${slug}`
    }

    if (type === CONTENTFUL_GRAPHQL_TYPES.PAGES_GROUP || type === CONTENTFUL_GRAPHQL_TYPES.CUSTOM_PAGE) {
        return `${section}/${slug}`
    }

    if (type === CONTENTFUL_GRAPHQL_TYPES.GUIDELINE) {
        return `/guidelines/${slug}`
    }

    if (type === CONTENTFUL_GRAPHQL_TYPES.CALCULATOR) {
        return `/health-professionals/calculators/${slug}`
    }

    if (type === JSON_ENTRY_TYPES.SERVICE) {
        return `/directory-of-services/${item.slug}`
    }

    if (type === CONTENTFUL_GRAPHQL_TYPES.DONATION_PAGE) {
        return `/donation-page/${item.slug}/`
    }

    if (type === CONTENTFUL_GRAPHQL_TYPES.DIRECTORY_SERVICES) {
        return '/directory-of-services'
    }

    if (type === CONTENTFUL_GRAPHQL_TYPES.LINK || type === CONTENTFUL_GRAPHQL_TYPES.LINK_PTP) {
        return item.url
    }

    console.error('\nMissing type for this item', item)

    return '#'
}

module.exports.buildURL = buildURL

const goto = (item, forcedType = null) => {
    const type = forcedType || getGraphqlType(item)

    if (type === CONTENTFUL_GRAPHQL_TYPES.LINK || type === CONTENTFUL_GRAPHQL_TYPES.LINK_PTP) {
        window.location.href = item.url
    } else {
        navigate(buildURL(item))
    }
}
module.exports.goto = goto
