import map from 'lodash/map'
import get from 'lodash/get'
/*
 * Functions used for parsing Contentful data entries fetched from Contentful API
 */

import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import richtextFormatConfig from '../utils/richtext'

export function parse(entry) {
    if ('Entry' === entry.sys.type) {
        if (entry.sys.contentType && entry.sys.contentType.sys.id) {
            switch (entry.sys.contentType.sys.id) {
                case 'widgetHeroBlock':
                    return parseWidgetHeroBlock(entry)
                case 'widgetHeroPTP':
                    return parseWidgetHeroBlockPTP(entry)
                case 'widgetStoryBlockPTP':
                    return parseWidgetStoryBlockPTP(entry)
                case 'widgetStoryBlock':
                    return parseWidgetStoryBlock(entry)
                case 'searchPanel':
                    return parseWidgetSearchPanel(entry)
                case 'widgetSearchPanelPTP':
                    return parseWidgetSearchPanelPTP(entry)
                case 'pagesGroup':
                    return parseWidgetPagesGroup(entry)
                case 'pageGroupPTP':
                    return parseWidgetPagesGroupPTP(entry)
                case 'widgetDonate':
                    return parseWidgetDonate(entry)
                case 'widgetLinks':
                    return parseWidgetLinks(entry)
                case 'widgetLinksPTP':
                    return parseWidgetLinksPTP(entry)
                case 'widgetRichText':
                    return parseWidgetRichText(entry)
                case 'widgetRichTextPtp':
                    return parseWidgetRichTextPTP(entry)
                case 'widgetLinkBlock':
                    return parseWidgetLinkBlock(entry)
                case 'widgetLinkBlockPTP':
                    return parseWidgetLinkBlockPTP(entry)
                case 'widgetImpact':
                    return parseWidgetImpact(entry)
                case 'widgetImpactPTP':
                    return parseWidgetImpactPTP(entry)
                case 'widgetSocialFeed':
                    return parseWidgetSocialFeed(entry)
                case 'widgetSocialFeedPTP':
                    return parseWidgetSocialFeedPTP(entry)
                default:
                    const message = `Can't parse entry type ${entry.sys.contentType.sys.id}, please check the code and update`
                    console.error(message)
                    return entry.fields || message
            }
        }
    }
}

function parseWidgetStoryBlock(entry) {
    return {
        __typename: 'ContentfulWidgetStoryBlock',
        ...entry.fields,
        backgroundImage: entry.fields.backgroundImage.fields,
        story: entry.fields.story.fields,
    }
}
function parseWidgetStoryBlockPTP(entry) {
    return {
        __typename: 'ContentfulWidgetStoryBlockPtp',
        ...entry.fields,
        backgroundImage: entry.fields.backgroundImage.fields,
    }
}

function parseWidgetHeroBlock(entry) {
    return {
        __typename: 'ContentfulWidgetHero',
        ...entry.fields,
        backgroundImage: entry.fields.backgroundImage.fields,
        summary: {
            childContentfulRichText: {
                html: documentToHtmlString(entry.fields.summary, richtextFormatConfig),
            },
        },
        link: get(entry, 'fields.link.fields'),

        featureList: map(entry.fields.featureList, (feature) => {
            if (feature.__typename === 'widgetStoryBlock') {
                return parseWidgetStoryBlock(feature)
            } else {
                const linkBlock = parseWidgetLinkBlock(feature)
                return {
                    ...linkBlock,
                    featureLink: linkBlock.link,
                }
            }
        }),
    }
}
function parseWidgetHeroBlockPTP(entry) {
    return {
        __typename: 'ContentfulWidgetHeroPtp',
        ...entry.fields,
        backgroundImage: entry.fields.backgroundImage.fields,
        summary: {
            childContentfulRichText: {
                html: documentToHtmlString(entry.fields.summary, richtextFormatConfig),
            },
        },
        link: get(entry, 'fields.link.fields'),

        featureList: map(entry.fields.featureList, (feature) => {
            if (feature.__typename === 'widgetStoryBlockPTP') {
                return parseWidgetStoryBlock(feature)
            } else {
                const linkBlock = parseWidgetLinkBlock(feature)
                return {
                    ...linkBlock,
                    featureLink: linkBlock.link,
                }
            }
        }),
    }
}

function parseWidgetSearchPanel(entry) {
    return {
        __typename: 'ContentfulWidgetSearchPanel',
        ...entry.fields,
        bgImage: entry.fields.bgImage.fields,
        featureList: map(entry.fields.featureList, (feature) => {
            if (feature.__typename === 'widgetStoryBlock') {
                return parseWidgetStoryBlock(feature)
            } else {
                const linkBlock = parseWidgetLinkBlock(feature)
                return {
                    ...linkBlock,
                    featureLink: linkBlock.link,
                }
            }
        }),
    }
}
function parseWidgetSearchPanelPTP(entry) {
    return {
        __typename: 'ContentfulWidgetSearchPanelPtp',
        ...entry.fields,
        bgImage: entry.fields.bgImage.fields,
        featureList: map(entry.fields.featureList, (feature) => {
            if (feature.__typename === 'widgetStoryBlockPTP') {
                return parseWidgetStoryBlock(feature)
            } else {
                const linkBlock = parseWidgetLinkBlock(feature)
                return {
                    ...linkBlock,
                    featureLink: linkBlock.link,
                }
            }
        }),
    }
}
function parseWidgetPagesGroup(entry) {
    return {
        __typename: 'ContentfulPageGroup',
        contentful_id: entry.sys.id,
        ...entry.fields,
        image: entry.fields.image ? entry.fields.image.fields : null,
    }
}
function parseWidgetPagesGroupPTP(entry) {
    return {
        __typename: 'ContentfulPageGroupPtp',
        contentful_id: entry.sys.id,
        ...entry.fields,
        image: entry.fields.image ? entry.fields.image.fields : null,
    }
}

function parseWidgetDonate(entry) {
    return {
        __typename: 'ContentfulWidgetDonate',
        ...entry.fields,
        notice: {
            notice: entry.fields.notice,
        },
    }
}

function parseWidgetLinks(entry) {
    return {
        __typename: 'ContentfulWidgetLinks',
        ...entry.fields,
        image: entry.fields.image.fields,
        links: parseLinkList(entry.fields.links),
    }
}
function parseWidgetLinksPTP(entry) {
    return {
        __typename: 'ContentfulWidgetLinksPtp',
        ...entry.fields,
        image: entry.fields.image.fields,
        links: parseLinkList(entry.fields.links),
    }
}
function parseWidgetRichText(entry) {
    return {
        __typename: 'ContentfulWidgetRichText',
        ...entry.fields,
        content: {
            childContentfulRichText: {
                html: documentToHtmlString(entry.fields.content, richtextFormatConfig),
                internal: {
                    content: entry.fields.content,
                },
            },
        },
    }
}
function parseWidgetRichTextPTP(entry) {
    return {
        __typename: 'ContentfulWidgetRichTextPtp',
        ...entry.fields,
        content: {
            childContentfulRichText: {
                html: documentToHtmlString(entry.fields.content, richtextFormatConfig),
                internal: {
                    content: entry.fields.content,
                }
            },
        },
    }
}

function parseWidgetLinkBlock(entry) {
    return {
        __typename: 'ContentfulWidgetLinkBlock',
        ...entry.fields,
        summary: {
            summary: entry.fields.summary,
        },
        backgroundImage: entry.fields.backgroundImage.fields,
        buttonLink: parseLinkList(entry.fields.link),
    }
}
function parseWidgetLinkBlockPTP(entry) {
    return {
        __typename: 'ContentfulWidgetLinkBlockPtp',
        ...entry.fields,
        summary: {
            summary: entry.fields.summary,
        },
        backgroundImage: entry.fields?.backgroundImage?.fields,
        buttonLink: parseLinkList(entry.fields.link),
    }
}

export function parseLinkList(linkList) {
    function parseLink(link) {
        let typename
        if (link.sys.contentType.sys.id === 'genericPage') {
            typename = 'ContentfulPageGeneric'
        }  else if (link.sys.contentType.sys.id === 'guideline') {
            typename = 'ContentfulPageGuideline'
        } else if (link.sys.contentType.sys.id === 'pageStory') {
            typename = 'ContentfulPageStory'
        } else if (link.sys.contentType.sys.id === 'widgetsPage') {
            typename = 'ContentfulPageWidgets'
        } else if (link.sys.contentType.sys.id === 'pagesGroup') {
            typename = 'ContentfulPageGroup'
        } else if (link.sys.contentType.sys.id === 'directoryOfServices') {
            typename = 'ContentfulPageDirectoryOfServices'
        } else if (link.sys.contentType.sys.id === 'customPage') {
            typename = 'ContentfulPageCustom'
        } else if (link.sys.contentType.sys.id === 'pageDonation') {
            typename = 'ContentfulPageDonation'
        } else if (link.sys.contentType.sys.id === 'link') {
            typename = 'ContentfulWidgetLink'
        } else if (link.sys.contentType.sys.id === 'widgetLinkPTP') {
            typename = 'ContentfulWidgetLinkPtp'
        } else if (link.sys.contentType.sys.id === 'pageGenericPTP') {
            typename = 'ContentfulPageGenericPtp'
        } else if (link.sys.contentType.sys.id === 'pageDirectoryOfServicesPTP') {
            typename = 'ContentfulPageDirectoryOfServicesPtp'
        } else if (link.sys.contentType.sys.id === 'pageGroupPTP') {
            typename = 'ContentfulPageGroupPtp'
        } else if (link.sys.contentType.sys.id === 'pageWidgetsPTP') {
            typename = 'ContentfulPageWidgetsPtp'
        }

        const linkObj = {
            ...link.fields,
            __typename: typename,
        }

        if (link.fields.icon) {
            linkObj.icon = link.fields.icon.fields
        }

        return linkObj
    }

    if (Array.isArray(linkList)) {
        return linkList.map(parseLink)
    } else if (typeof linkList === 'object' && linkList !== null) {
        return parseLink(linkList)
    }

    return null
}

function parseWidgetImpact(entry) {
    return {
        __typename: 'ContentfulWidgetImpact',
        ...entry.fields,
        introduction: {
            introduction: entry.fields.introduction,
        },
        items: parseLinkList(entry.fields.items),
        link: entry.fields.link.fields,
    }
}
function parseWidgetImpactPTP(entry) {
    return {
        __typename: 'ContentfulWidgetImpactPtp',
        ...entry.fields,
        introduction: {
            introduction: entry.fields.introduction,
        },
        items: parseLinkList(entry.fields.items),
        link: entry.fields.link.fields,
    }
}

function parseWidgetSocialFeed(entry) {
    return {
        __typename: 'ContentfulWidgetSocialFeed',
        ...entry.fields,
    }
}
function parseWidgetSocialFeedPTP(entry) {
    return {
        __typename: 'ContentfulWidgetSocialFeedPtp',
        ...entry.fields,
    }
}
