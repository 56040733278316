import React, { PureComponent } from 'react'
import { connectStateResults, connectHits, Hits, InstantSearch, Configure } from 'react-instantsearch-dom'
import map from 'lodash/map'
import { withOverlayContext } from '../../../contexts'
import SearchBox from '../../search/SearchBox'
import SearchResultGuideline from './SearchResultGuideline'
import SearchResultPage from './SearchResultPage'
import SearchResultHealthpoint from './SearchResultHealthpoint'
import SearchResultCalculator from './SearchResultCalculator'

/**
 * SearchBar used in header and SearchPanel widget
 */

const HITS_PER_PAGE = 5

class SearchBar extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isActive: !!props.alwaysActive,
            isAutoCompleteActive: false,
            isSearchBoxFocused: false,
            loaded: !!props.alwaysActive, // the InstantSearch component makes a query to fetch data from algolia as soon as it gets rendered. We avoid that fetch using this flag.
        }
    }

    openSearchBar = () => {
        this.setState({
            loaded: true,
            isActive: true,
            isSearchBoxFocused: true,
        })

        this.openAutoComplete()

        if (this.props.openSearch) {
            this.props.openSearch()
        }
    }

    openAutoComplete = () => {
        this.setState({
            isAutoCompleteActive: true,
        })
        this.openOverlay()
    }

    openOverlay = () => {
        const { withOverlay = true, overlayContext } = this.props
        if (withOverlay) {
            overlayContext.showOverlay()
        }
    }

    closeSearchBar = () => {
        if (!this.props.alwaysActive) {
            this.setState({
                isActive: false,
            })
        }

        this.setState({
            isSearchBoxFocused: false,
        })

        this.closeAutoComplete()
        if (this.props.closeSearch) {
            this.props.closeSearch()
        }
    }

    closeOverlay = () => {
        const { withOverlay = true, overlayContext } = this.props
        withOverlay && overlayContext.hideOverlay()
    }

    closeAutoComplete = () => {
        this.setState(
            {
                isAutoCompleteActive: false,
            },
            () => {
                this.closeOverlay()
            }
        )
    }

    render() {
        const { id, activeSection, isPowerToProtect } = this.props
        const { loaded, isActive, isAutoCompleteActive, isSearchBoxFocused } = this.state

        return (
            <div id={id} className={`search ${isActive ? 'open' : ''}`}>
                {loaded ? (
                    <InstantSearch appId={process.env.GATSBY_ALGOLIA_APP_ID} apiKey={process.env.GATSBY_ALGOLIA_SEARCH_KEY} indexName={isPowerToProtect ? 'power-to-protect' : 'starship'}>
                        <Configure analytics={true} hitsPerPage={HITS_PER_PAGE} facetingAfterDistinct={true} facetFilters={activeSection ? [`userSection:${activeSection}`] : undefined} />
                        {this.renderSearchBar(<SearchBox isPowerToProtect={isPowerToProtect} searchBarId={id} activeSection={activeSection} isActive={isSearchBoxFocused} onBlur={() => this.closeSearchBar()} onFocus={() => this.openSearchBar()} />)}
                        <div className={`search-results-panel ${isAutoCompleteActive ? 'show' : ''}`}>
                            <AutoComplete />
                        </div>
                    </InstantSearch>
                ) : (
                    this.renderSearchBar()
                )}
            </div>
        )
    }

    renderSearchBar(children) {
        return (
            <div className="search-bar">
                <div className="search-icon" onClick={() => this.openSearchBar()}>
                    <i className="far fa-search" />
                </div>
                {children}
            </div>
        )
    }
}

const AutoComplete = connectStateResults(({ searchState }) =>
    searchState && searchState.query ? (
        <div className="quick-results-panel quick-search">
            <div className="title">Quick Search</div>
            <Hits hitComponent={SearchResult} />
        </div>
    ) : (
        <div className="quick-results-panel quick-links">
            <div className="inner">
                <div className="title">Popular Links</div>
                <QuickLinks />
            </div>
        </div>
    )
)

/*
 * Current QuickLinks only returns the first 5 records of default search results(search key is empty)
 * Ranking strategy: https://www.algolia.com/doc/guides/managing-results/must-do/custom-ranking/
 * After upgrade to Non-community version of algolia, we might rank the result by analytics and get the most popular results
 */
const QuickLinks = connectHits(({ hits }) => (
    <div className="list">
        {map(hits.slice(0, 5), (hit) => (
            <SearchResult hit={hit} key={hit.id} />
        ))}
    </div>
))

const SearchResult = ({ hit }) => {
    if (hit.type === 'GUIDELINE') {
        return <SearchResultGuideline hit={hit} />
    }

    if (hit.type === 'PAGE') {
        if (hit.guidelineType && hit.guidelineType.toLowerCase() === 'calculator') {
            return <SearchResultCalculator hit={hit} />
        }
        return <SearchResultPage hit={hit} />
    }

    if (hit.type === 'HEALTHPOINT') {
        return <SearchResultHealthpoint hit={hit} />
    }

    return <p>{`Undefined result ${hit.title || hit.name} type ${hit.type}`}</p>
}

export default withOverlayContext(SearchBar)
