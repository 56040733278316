import React, { PureComponent } from 'react'

const HealthPointServiceContext = React.createContext()

export default class HealthPointServiceProvider extends PureComponent {
    constructor() {
        super()

        this.state = {
            info: {},
            setHealthPointService: this.setHealthPointService,
        }
    }

    setHealthPointService = (service) => {
        this.setState({
            info: service
        })
    }

    render = () => (
        <HealthPointServiceContext.Provider value={this.state}>
            {this.props.children}
        </HealthPointServiceContext.Provider>
    )
}

export function withHealthPointServiceContext(Component) {
    return function WrapperComponent(props) {
        return (
            <HealthPointServiceContext.Consumer>
                {state => <Component {...props} healthPointServiceContext={state} />}
            </HealthPointServiceContext.Consumer>
        );
    };
}