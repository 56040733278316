import React, { PureComponent } from 'react'

const OverlayContext = React.createContext()

export default class OverlayProvider extends PureComponent {
    constructor() {
        super()

        this.state = {
            isActive: false,
            showOverlay: () => this.showOverlay(),
            hideOverlay: () => this.hideOverlay(),
            toggleOverlay: () => this.toggleOverlay()
        }
    }

    toggleOverlay = () => {
        this.setState({
            isActive: !this.state.isActive
        })
    }

    showOverlay = () => {
        this.setState({
            isActive: true
        })

        document.body.classList.add("no-scroll")
    }

    hideOverlay = () => {
        this.setState({
            isActive: false
        })

        document.body.classList.remove("no-scroll")
    }

    render = () => (
        <OverlayContext.Provider value={this.state}>
            {this.props.children}
        </OverlayContext.Provider>
    )
}

export function withOverlayContext(Component) {
    return React.forwardRef((props, ref) => {
        return (
            <OverlayContext.Consumer>
                {state => <Component {...props} overlayContext={state} ref={ref} />}
            </OverlayContext.Consumer>
        )
    })
}