import React, { Component } from 'react'
import { Link } from 'gatsby'
import { buildURL } from '../../utils/url'

export default class SitemapWidget extends Component {

  render() {
    const {activeSection, sitemap} = this.props
    return (
      <div className={`links${activeSection === sitemap.userSection ? ' mobile' : ''} col-xs-12 col-sm-6 col-md-6 col-xl-3`}>
        <h6>{sitemap.title}</h6>
        <ul>
          {
            sitemap.links.map(link => <li key={link.slug}><Link to={buildURL(link)}>{ link.title }</Link></li>)
          }
        </ul>
      </div>
    )
  }
}