import uuid from 'uuid/v4'
import isEmpty from 'lodash/isEmpty'

const EventTracker = {}

// Our custom events
const EVENT_TYPES = Object.freeze({
    ONE_OFF_DONATION: 'ONE_OFF_DONATION',
    RECURRING_DONATION: 'RECURRING_DONATION',
    CLICK: 'CLICK',
    SEARCH: 'SEARCH',
})

EventTracker.EVENT_TYPES = EVENT_TYPES

const EVENT_CATEGORIES = Object.freeze({
    DONATE_WIDGET: 'donateWidget',
    HERO_WIDGET: 'heroWidget',
    LINK_BLOCK: 'linkBlockWidget',
    LINKS: 'linksWidget',
    PAGES_GROUP: 'pagesGroupWidget',
    SEARCH: 'search',
    STORY_BLOCK: 'storyBlockWidget',
})

EventTracker.EVENT_CATEGORIES = EVENT_CATEGORIES

// Map from our custom events to facebook standard events.
export const FACEBOOK_EVENTS = {
    Lead: 'Lead',
    AddToCart: 'AddToCart',
    InitiateCheckout: 'InitiateCheckout',
    [EVENT_TYPES.ONE_OFF_DONATION]: 'Purchase',
    [EVENT_TYPES.RECURRING_DONATION]: 'Subscribe',
}

const _window = typeof window !== 'undefined' ? window : {}

/**
 * EventTracker relies on 3rd party libraries that are added globaly. To make sure EventTracker can fire those events we need to wait for those libraries to be available.
 * We have a retryCount because if this promise never resolves (doing npm run build) our netlify build will hang for 30 minutes and then timeout.
 * @returns {Promise}
 */
const MAX_RETRY_TIMES = 20;
const RETRY_TIMEOUT = 400; // milliseconds
const isReadyPromise = () => {
    return new Promise((resolve, reject) => {
        let retryCount = 0
        const waiting = setInterval(() => {
            if (_window && _window.ga && _window.fbq) {
                clearInterval(waiting)
                resolve()
            } else if (++retryCount === MAX_RETRY_TIMES) {
                clearInterval(waiting)
                reject('Tracking libraries are not ready.')
            }
        }, RETRY_TIMEOUT)
    })
}

EventTracker.ready = () => {
    return isReadyPromise()
}

/**
 * Tracks an event using multiple libraries.
 * @param {object} - details of the event
 * @property {string} eventType - A value from the EVENT_TYPES constants.
 * @property {number} amount - Value of the donation/transaction.
 */
EventTracker.track = ({ eventType, amount, frequency }) => {
    facebookTracking(FACEBOOK_EVENTS[eventType], amount)
    googleTracking(eventType, amount, frequency)
}

/**
 * Tracks an event using Facebook pixel.
 * @param {FACEBOOK_EVENTS[]} eventType - Event type to track.
 * @param {Number} amount - value of the transaction to track.
 */
export function facebookTracking(eventType, amount) {
    try {
        const details = {}
        if (eventType === FACEBOOK_EVENTS[EVENT_TYPES.ONE_OFF_DONATION] || eventType === FACEBOOK_EVENTS[EVENT_TYPES.RECURRING_DONATION]) {
            _window.fbq('track', 'Donate') // For donations we track an extra event called "Donate"
            details.value = amount || 0
            details.currency = 'NZD'
        }

        if (eventType === EventTracker.EVENT_TYPES.RECURRING_DONATION) {
            details.predicted_ltv = 12 // We have no certainty on how long a recurring donation will go on for so we assume 12 months.
        }

        if (!isEmpty(details)) {
            _window.fbq('track', eventType, details)
        } else {
            _window.fbq('track', eventType)
        }
    } catch (err) {
        // TODO: error handling.
    }
}

/**
 * Tracks an event using Google tag manager.
 * @param {EVENT_TYPE[]} eventType - Event type to track.
 * @param {Number} amount - value of the transaction to track.
 * @param {String} frequency - value of the transaction frequency (for recurring donations).
 */
function googleTracking(eventType, amount, frequency) {
    try {
        const transactionId = uuid()
        _window.dataLayer.push({
            event: 'purchase',
            transactionId,
            transactionTotal: amount,
            transactionCurrency: 'NZD',
            transactionProducts: [
                {
                    sku: frequency.toUpperCase(),
                    name: eventType,
                    quantity: 1,
                    category: 'Donations',
                    price: amount,
                    currency: 'NZD'
                },
            ],
        })
    } catch (err) {
        // TODO: error handling.
    }
}

/**
 * Custom event tracking for google analytics
 * @param {string} eventCategory Typically the object that was interacted with
 * @param {string} eventAction The type of interaction
 * @param {string} eventLabel Useful for categorizing events
 * @param {stirng} eventValue A numeric value associated with the event
 */
function customEvent(eventCategory, eventAction, eventLabel, eventValue) {
    _window.dataLayer.push({
        event: 'custom',
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
    })
}

EventTracker.customEvent = customEvent

export default EventTracker
