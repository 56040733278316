import React, { PureComponent } from 'react'
import {groupGuidelineSections, getGuidelineIndexes, getDosageTables} from './../helpers/GuidelineHelper'

const GuidelineContext = React.createContext()

export default class GuidelineProvider extends PureComponent {
    constructor() {
        super()

        this.state = {
            info: {},
            indexes: [],
            sections: null,
            setGuideline: this.setGuideline,
            dosageTables: [],
            disclaimer: '',
        }
    }

    setGuideline = (guideline) => {
        // Do we have a rich text body?
        if(guideline.richtext) {
            guideline.htmlBody = guideline.richtext.childContentfulRichText.html
        } else {
            guideline.htmlBody = ''
        }

        guideline.documentType = !!guideline.guidelineType ? guideline.guidelineType.guidelineType : ''

        if (guideline && guideline.summary) {
            guideline.htmlSummary = guideline.summary.childMarkdownRemark.html
        }
        this.setState({
            info: guideline,
            ...groupGuidelineSections(guideline),
            disclaimer: guideline && guideline.disclaimer ? guideline.disclaimer.content.childContentfulRichText.html : ''
        }, () => {
            this.setState({
                indexes: getGuidelineIndexes(),
                dosageTables: getDosageTables()
            })
        })
    }

    render = () => (
        <GuidelineContext.Provider value={this.state}>
            {this.props.children}
        </GuidelineContext.Provider>
    )
}

export function withGuidelineContext(Component) {
    return function WrapperComponent(props) {
        return (
            <GuidelineContext.Consumer>
                {state => <Component {...props} guidelineContext={state} />}
            </GuidelineContext.Consumer>
        );
    };
}