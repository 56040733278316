const { MARKS, INLINES, BLOCKS } = require('@contentful/rich-text-types')
const get = require('lodash/get')
const map = require('lodash/map')
const url = require('./url');
const buildURL = url.buildURL;
const CONTENTFUL_ENTRY_TYPES = url.CONTENTFUL_ENTRY_TYPES;
const getShortURL = require('./../../src/utils/assets').getShortURL;

const locale = 'en-NZ'

module.exports = {
    renderNode: {
        [INLINES.ASSET_HYPERLINK]: renderAssetHyperlink,
        [INLINES.ENTRY_HYPERLINK]: renderEntryHyperlink,
        [INLINES.EMBEDDED_ENTRY]: renderEntryByType,
        [INLINES.HYPERLINK]: renderHyperlink,
        [BLOCKS.EMBEDDED_ENTRY]: renderEntryByType,
        [BLOCKS.EMBEDDED_ASSET]: renderAssetByType,
        [BLOCKS.PARAGRAPH]: renderParagraph,
    },
    /*
     * Defines custom html string for each mark type like bold, italic etc..
     */
    renderMark: {
        [MARKS.BOLD]: text => `<strong>${text}</strong>`,
        [MARKS.SUBSCRIPT]: text => `<sub>${text}</sub>`,
        [MARKS.SUPERSCRIPT]: text => `<sup>${text}</sup>`,
    },
}

function errorRendering(node) {
    return `<div>Error with Entry. This could mean the Entry you're trying to access it's not available anymore. Please review it in the CMS: ${JSON.stringify(node)}</div>`
}

function renderEntryByType(node) {
    try {
        const typeId = get(node, 'data.target.sys.contentType.sys.id')
        if (!typeId) {
            return errorRendering(node);
        }

        if (typeId === 'alert') {
            return renderAlert(node)
        }

        if (typeId === 'table') {
            return renderTable(node)
        }

        if (typeId === 'gallery') {
            return renderGallery(node)
        }

        if (typeId === 'video') {
            return renderVideo(node)
        }

        if (typeId === 'screenCast') {
            return renderScreencast(node)
        }

        if (typeId === 'imageSmart') {
            return renderImageSmart(node)
        }

        if (typeId === 'embed3rdParty') {
            return renderEmbed3rdParty(node)
        }
        
        if (typeId === 'widgetImage') {
            return renderWidgetImage(node);
        }
    
        if (typeId === 'widgetEmbedded') {
            return renderWidgetEmbed(node);
        }

        return renderGenericEntry(node)
    } catch (error) {
        console.warn(`Error on node ${JSON.stringify(node)} : ${error}`)
        return errorRendering(node);
    }
}

/**
 * This method returns the value of a field. If it has a property with the same value as locale it returns that or else just the field.
 * @param {Object} node
 * @param {String} fieldPath - Path to the field. e.g.: fields.title
 */
function getField(node, fieldPath) {
    const field = get(node, fieldPath)
    return get(field, locale, field)
}

function renderGallery(node) {
    const images = node.data.target.fields['images'][locale] || node.data.target.fields['images']
    const items = map(images, image => {
        const file = getField(image, 'fields.file')
        const title = getField(image, 'fields.title')
        const description = getField(image, 'fields.description') ? getField(image, 'fields.description') : ''

        return `<div class="item">
                <img 
                    oncontextmenu="return false;"
                    src="https:${file.url}" 
                    title="${title}"
                    alt="${description}" />
                <div class="item-caption">${description}</div>
            </div>`
    })

    const position = getField(node, 'data.target.fields.position')
    const title = getField(node, 'data.target.fields.title')
    const slug = getField(node, 'data.target.fields.slug')
    const wrapText = getField(node, 'data.target.fields.wrapText');

    return `<div id="${slug}" class="gallery" data-wrap-text="${wrapText}" data-position="${position}">
            <div class="gallery-caption">
                ${title}
            </div>
            ${items.join('')}
        </div>`
}

function renderWidgetImage(node) {
    const images = node.data.target.fields['images'][locale] || node.data.target.fields['images']
    if(!images) return `<div></div>`;
    if (images.length > 1) {
        return renderGallery(node)
    }
    const file  = getField(images[0], 'fields.file')
    if (file && file.contentType.includes('image/')) {
        const enableHyperlink = getField(node, 'data.target.fields.enableHyperlink')
        let hyperlink = getField(node, 'data.target.fields.hyperlink')
        const title = !node.data.target.fields.title ? '' : node.data.target.fields.title[locale] ? node.data.target.fields.title[locale] : node.data.target.fields.title
        const position = getField(node.data.target.fields, 'position') ? getField(node.data.target.fields, 'position').toLowerCase(): 'left';
        let width = !node.data.target.fields.width ? 100 : node.data.target.fields.width[locale] ? node.data.target.fields.width[locale] : node.data.target.fields.width
        width = width > 100 ? 100 : width
        const wrapText = getField(node, 'data.target.fields.wrapText')
        const wrapClass = !wrapText ? 'no-wrap' : '';
        const img = `<img title="${title}" src="${"https:" + file.url}" class="${"image-align-" + position} ${wrapClass}"  style="${"width:" + width + "%"}" />`
        
        const hyperlinkEntryArray = getField(node, 'data.target.fields.hyperlinkEntry')
        if(hyperlinkEntryArray) {
            const hyperlinkEntry = hyperlinkEntryArray[0]

            if(hyperlinkEntry.sys.contentType.sys.id === "media"){
                const media = getField(hyperlinkEntry, 'fields.media');
                const url = getField(media, 'fields.file.url');
                const assetId = getField(media, 'sys.contentful_id');
                const shortURL = getShortURL(assetId, url);
                hyperlink = shortURL ? shortURL : url;
            }
            else {
                hyperlink = buildURL({ ...hyperlinkEntry })
            }
        }
        if (enableHyperlink)
            return `<a href="${hyperlink}" target="_blank" class="entry-link ${wrapClass}"> ${img}</a>`
    
        return `${img}`
    }

    return renderGenericEntry(node)
}

function renderImageSmart(node) {
    const file = !node.data.target.fields.media ? undefined : node.data.target.fields.media.fields ? node.data.target.fields.media.fields.file : node.data.target.fields.media[locale].fields.file[locale]
    if (file && file.contentType.includes('image/')) {
        const enableHyperlink = !node.data.target.fields.enableHyperlink ? '' : node.data.target.fields.enableHyperlink[locale] ? node.data.target.fields.enableHyperlink[locale] : node.data.target.fields.enableHyperlink
        const hyperlink = !node.data.target.fields.hyperlink ? '' : node.data.target.fields.hyperlink[locale] ? node.data.target.fields.hyperlink[locale] : node.data.target.fields.hyperlink
        const title = !node.data.target.fields.title ? '' : node.data.target.fields.title[locale] ? node.data.target.fields.title[locale] : node.data.target.fields.title
        const position = getField(node.data.target.fields, 'position');
        let width = !node.data.target.fields.width ? 100 : node.data.target.fields.width[locale] ? node.data.target.fields.width[locale] : node.data.target.fields.width
        width = width > 100 ? 100 : width

        const img = `<img oncontextmenu="return false;" title="${title}" src="${"https:" + file.url}" class="${"image-align-" + position.toLowerCase()}"  style="${ "width:" + width + "%"}" />`
        if (enableHyperlink)
            return `<a href="${hyperlink}" target="_blank" class="entry-link"> ${img}</a>`

        return `${img}`
    }

    return renderGenericEntry(node)
}

function renderEmbed3rdParty(node) {
    const title = getField(node, 'data.target.fields.title');
    const url = getField(node, 'data.target.fields.url');
    return (
        `<div class="embed-responsive embed-responsive-16by9">
            <iframe
                title="${title}"
                class="embed-responsive-item"
                src="${url}"
                frameborder="0"
                allowfullscreen
            ></iframe>
        </div>`
    );
}

function renderAlert(node) {
    return `<div class='alert'>${node.data.target.fields.text[locale] || node.data.target.fields.text}</div>`
}

function renderTable(node) {
    const contentFieldName = 'test'
    const value = getField(node, `data.target.fields.${contentFieldName}`)
    const isDosageTable = getField(node, 'data.target.fields.isDosageTable') || false
    const tableName = getField(node, 'data.target.fields.title')
    const slug = getField(node, 'data.target.fields.slug')

    // Removing styles from table td
    const tableUpdated = value.replace(/style="[^"]*"/g, "").replace(/<a/g, `<a target="_blank"`);
    return `<div class='custom-entry table-responsive ${isDosageTable ? 'dosage-table' : ''}' data-name='${tableName}' id='${slug}'>${tableUpdated}</div>`
}

/**
 * When we recieve an entry that we do not recognise we show a warning in the console and try to render something so developers would get a hint on what's wrong.
 * @param {Object} node
 * @returns {String} html string
 */
function renderGenericEntry(node) {
    console.warn(`\nThere's no render method for generic entry of type ${node.data.target.sys.contentType.sys.id}`)
    const fields = node.data.target.fields
    const field = fields.name || fields.title || fields[0] // We are trying to guess an existing field to show something.
    const value = !field ? 'empty' : field[locale] || field

    return `<div class='unknown-entry'>
            <p>Please implement a render method to display the entry: ${value}</p>
        </div>`
}

function getAssetType(node) {
    try {
        if (node.data.target.fields && node.data.target.fields.file) {
            const file = node.data.target.fields.file[locale] || node.data.target.fields.file
            return file.contentType;
        } else return null;
    } catch (error) {
        console.warn(`Error on node ${JSON.stringify(node)} : ${error}`);
        return null;
    }
}

/**
 * This method will render assets depending if it's an image or a link to download an asset (pdf/zip/etc) or if it doesn't recognize the asset it will write a warning in the terminal and render the asset as a link.
 * @param {Object} node 
 * @param {Function} content
 * @returns {String} html string
 */
function renderAssetByType(node, content) {
    const type = getAssetType(node) || 'image/jpeg'

    if (!type) {
        console.warn(`Error when rendering node ${JSON.stringify(node)}`);
        return errorRendering(node);
    }

    if (/^image\/.+/i.exec(type)) {
        return renderImage(node)
    }

    return renderGenericAsset(node, content)
}

function renderImage(node) {
    try {
        if (node.data.target.fields && node.data.target.fields.file) {
            const src = node.data.target.fields.file[locale] ?
                node.data.target.fields.file[locale].url :
                node.data.target.fields.file.url
            const title = node.data.target.fields.title[locale] || node.data.target.fields.title
            return `<div id="asset-${getSlug(title)}" class="image-container text-center">
                    <img src="${src}" alt="${title}" title="${title}" oncontextmenu="return false;" />
                </div>`
        } else return errorRendering(node);
    } catch (error) {
        console.warn(`Error when rendering node ${JSON.stringify(node)} : ${error}`);
        return errorRendering(node);
    }
}

/**
 * This method shows a warning for a component that we don't support.
 * @param {Object} node
 * @returns {String} html string
 */
function renderGenericAsset(node, children) {
    const fields = get(node, 'data.target.fields')
    const file = get(fields, `file[${locale}]`, fields.file)
    console.warn(`\nThere's no render method for generic asset of type ${file.contentType}`)

    return renderHyperlink(node, children)
}

/**
 * This method will render assets as anchor hyperlinks if we regonize them as such (pdf, zip, etc) or will create local links prefixed with "asset-". For example a link to an image/table that exists in the same page.
 * @param {Object} node
 * @param {Function} children
 * @returns {String} html string
 */
function renderAssetHyperlink(node, children) {
    const type = getAssetType(node)

    if (!type) {
        console.warn(`Error when rendering node ${JSON.stringify(node)}`);
        return errorRendering(node);
    }

    if (/^image\/.+/i.exec(type)) {
        const assetTitle = getField(node, 'data.target.fields.title') || ''
        return `<a href="#${getSlug(assetTitle, 'asset')}" class="asset-link">${children(node.content)}</a>`
    }

    return renderHyperlink(node, children)

}

function renderVideo(node) {
    const fields = get(node, 'data.target.fields')
    const videoUrl = get(fields, `videoUrl[${locale}]`, fields.videoUrl)
        .replace('youtu.be', 'www.youtube.com/embed')
        .replace('watch?v=', 'embed/')
        .replace('vimeo.com', 'player.vimeo.com/video')

    return `<div class="embed-responsive embed-responsive-16by9">
        <iframe
            class="embed-responsive-item"
            src="${videoUrl}"
            frameborder="0"
            allowfullscreen
        ></iframe>
        </div>`
}

function renderEntryHyperlink(node, children) {
    const type = get(node, 'data.target.sys.contentType.sys.id')
    const slug = getField(node, 'data.target.fields.slug')
    const text = children(node.content)

    const isExternalReference = type && Object.values(CONTENTFUL_ENTRY_TYPES).includes(type)
    if (isExternalReference) {
        const url = buildURL({
            slug: slug,
            userSection: getField(node, 'data.target.fields.userSection'),
            sys: node.data.target.sys
        })
        return `<a href="${url}" target="_blank" class="entry-link">${text}</a>`
    }

    const url = slug ? `#${slug}` : getField(node, 'data.target.fields.url')
    const isAnchor = url ? url.includes('#') : false
    const targetAttr = isAnchor ? '' : '_blank'
    return `<a ${targetAttr} href="${url}" class="entry-link">${text}</a>`
}

function shouldOpenInNewTab(uri) {
    return !/^mailto:.*/.test(uri) && !/^tel:.*/.test(uri);
}

function renderHyperlink(node, children) {
    let uri = getField(node, 'data.uri')
    if (!uri) {
        const file = getField(node, 'data.target.fields.file');
        if (file) {
            uri = file.url
        }
    }

    const content = children(node.content) || getField(node, 'data.target.fields.title')

    if (!uri) {
        uri = "";
    }
    const assetId = getField(node, 'data.target.sys.contentful_id');
    const shortURL = getShortURL(assetId, uri);
    if(shortURL) {
        uri = shortURL;
    }

    // New emails open in the same tab STAR-284
    // const targetAttr = shouldOpenInNewTab(uri) ? "_blank" : "";
    const isAnchor = uri ? uri.includes('#') : false
    const targetAttr = isAnchor ? "" : "_blank"
    return `<a href="${uri}" target="${targetAttr}">${content}</a>`
}

function getSlug(title, prefix) {
    const slug = title
        .replace(/[^\w\s\d-]/gi, '')
        .replace(/\s+/g, '-')
        .toLowerCase()

    return prefix ? `${prefix}-${slug}` : slug
}

function renderParagraph(node, next) {
    const paragraph = '<p>' + next(node.content) + '</p>'
    if (paragraph === '<p></p>') {
        return '<p>&nbsp;</p>'
    }
    return paragraph.replace(/\n/g, '<br>')
}

function renderScreencast(node) {
    const fields = get(node, 'data.target.fields')
    const embedCode = get(fields, `embedCode[${locale}]`, fields.embedCode)

    const regexp = RegExp('src="(.*?)"', 'g')

    const src = regexp.exec(embedCode)

    if (src == null || src[1] == null) {
        return 'Please check ScreenCast embed code'
    }

    return `<div class="embed-responsive embed-responsive-16by9">
        <iframe
            class="embed-responsive-item"
            src="${src[1]}"
            frameborder="0"
            allowfullscreen
        ></iframe>
        </div>`
}

function renderWidgetEmbed(node) {
    const fields = get(node, 'data.target.fields');
    const title = get(fields, `title[${locale}]`, fields.title);
    const type = get(fields, `type[${locale}]`, fields.type);
    const urlOrCode = get(fields, `url[${locale}]`, fields.url);
    const htmlCode = get(fields, 'htmlCode.fields.file.url', '');
    const htmlUrl = get(fields, `htmlCode[${locale}][fields][file][${locale}][url]`, htmlCode);
    
    if (type.toLowerCase() === 'url') {
        const url = urlOrCode.replace('youtu.be', 'www.youtube.com/embed')
            .replace('watch?v=', 'embed/')
            .replace('vimeo.com', 'player.vimeo.com/video');
        
        return (
            `<div class="embed-responsive embed-responsive-16by9">
                <iframe
                    title="${title}"
                    class="embed-responsive-item"
                    src="${url}"
                    frameborder="0"
                    allowfullscreen></iframe>
            </div>`
        );
    }

    if (type.toLowerCase() === 'embed file') {
        return (
            `<iframe
                title="${title}"
                type="text/html"
                data-embed-id="${title.replace(/ /g, "_")}"
                data-embed-url="${htmlUrl}"
                id="custom-embed"
                class="w-100"
                frameborder="0"
                allowfullscreen>
            </iframe>`
        );
    }

    const regexp = RegExp('src="(.*?)"', 'g');

    const src = regexp.exec(urlOrCode);

    if (src == null || src[1] == null) {
        return 'Please check ScreenCast embed code';
    }

    return (
        `<div class="embed-responsive embed-responsive-16by9">
            <iframe
                title="${title}"
                class="embed-responsive-item"
                src="${src[1]}"
                frameborder="0"
                allowfullscreen></iframe>
        </div>`
    );
}
