import React, { Component } from 'react'
import starshipLogo from '../../assets/images/logo-starship.png'
import ptpLogo from '../../assets/images/logo-ptp.png'
import { SECTIONS_ARRAY } from '../../utils/url'
import SitemapWidget from '../widgets/SitemapWidget'

export default class Footer extends Component {
    render() {
        const { activeSection, hideSocials, sitemap, isPowerToProtect } = this.props
        let sortedSitemap = sitemap?.sort((a, b) => {
            return SECTIONS_ARRAY.findIndex((section) => section.key === a.userSection) - SECTIONS_ARRAY.findIndex((section) => section.key === b.userSection)
        })
        sortedSitemap = sortedSitemap?.filter((sitemap) => !sitemap.title.includes("Don't delete"))

        const logo = isPowerToProtect ? ptpLogo : starshipLogo
        const info = isPowerToProtect ? '' : 'Our children. Better health. Brighter futures.'
        const copyright = isPowerToProtect ? '&copy; Power to Protect 2024' : '&copy; Starship 2019'

        const socialLinks = isPowerToProtect ? (
            <>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100091337803928">
                    <i className="fab fa-facebook-f"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/powertoprotect aotearoa/">
                    <i className="fab fa-instagram"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@power 2 protect">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
                        <path
                            d="M16.5888 5.38742C15.5902 5.17379 14.6858 4.64677 14.0077 3.88317C13.3295 3.11957 12.913 2.15933 12.8188 1.14242V0.701416H9.37384V14.3734C9.37171 14.9787 9.17998 15.5681 8.8256 16.0588C8.47122 16.5495 7.97199 16.9168 7.39809 17.1091C6.82418 17.3015 6.20443 17.3091 5.62593 17.1311C5.04743 16.953 4.53927 16.5982 4.17284 16.1164C3.80072 15.6266 3.5943 15.031 3.5835 14.4159C3.57271 13.8009 3.7581 13.1984 4.11281 12.6958C4.46751 12.1932 4.97307 11.8167 5.55619 11.6208C6.13931 11.425 6.76966 11.4199 7.35584 11.6064V8.10641C6.04869 7.92572 4.71775 8.15811 3.54919 8.77108C2.38062 9.38406 1.43288 10.347 0.838516 11.5251C0.244156 12.7032 0.0329081 14.0377 0.234321 15.3418C0.435735 16.6459 1.03973 17.8545 1.96184 18.7984C2.8412 19.6982 3.96872 20.3155 5.20049 20.5715C6.43226 20.8276 7.7124 20.7108 8.87749 20.236C10.0426 19.7613 11.0398 18.9501 11.7418 17.9061C12.4438 16.8621 12.8188 15.6325 12.8188 14.3744V7.38842C14.2111 8.38375 15.8804 8.91746 17.5918 8.91442V5.49142C17.2548 5.49183 16.9186 5.45698 16.5888 5.38742Z"
                            fill="currentColor"
                        />
                    </svg>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCysgvdTS6xYzykKugEH4ksg">
                    <i className="fab fa-youtube"></i>
                </a>
            </>
        ) : (
            <>
                <a target="_blank" rel="noopener noreferrer" href="http://www.facebook.com/starshipfoundation?ref=mf">
                    <i className="fab fa-facebook-f"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/StarshipFDN">
                    <i className="fab fa-twitter"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="http://www.youtube.com/user/StarshipFoundation?ob=5#p/a">
                    <i className="fab fa-youtube"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="http://www.instagram.com/starshipfoundation">
                    <i className="fab fa-instagram"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://mp.weixin.qq.com/s?__biz=MzAwMTU0ODM0MA==&mid=2247484335&idx=1&sn=e3089e7ba82fbf74788138503abcae66&chksm=9ad94af4adaec3e2ee1ee47a7795134d60a934156319068b9666938c868f9f9ecc1b48efa797&token=982561504&lang=zh_CN#rd&umid=141a2d93-48e1-4970-ae78-211ecf010c3f&auth=4208f9d292d4bb72367c9cdb6e8d9affc9f9dfab-b96bc4f52e6c95e0ffcdf1b8635a9b35f5b5adb4">
                    <i className="fab fa-weixin"></i>
                </a>
            </>
        )

        return (
            <footer className="app-footer">
                <div className="footer">
                    <div className="inner">
                        <div className="credits">
                            <a href="/" className="logo">
                                <img src={logo} alt="Logo" title="Logo" />
                            </a>
                            <div className="info">{info}</div>

                            {!hideSocials && <div className="social-links">{socialLinks}</div>}

                            <div className="copy" dangerouslySetInnerHTML={{ __html: copyright }} />
                        </div>

                        <div className="footer-links row">
                            {sortedSitemap?.map((links, index) => (
                                <SitemapWidget key={index} activeSection={activeSection} sitemap={links} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="contentful-footer">
                    <a href="https://www.contentful.com/" target="_blank" rel="noopener noreferrer">
                        <img src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg" width="120" alt="Powered by Contentful" title="Powered by Contentful" />
                    </a>
                </div>
            </footer>
        )
    }
}
