// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-browse-js": () => import("./../../../src/pages/_browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-directory-of-services-js": () => import("./../../../src/pages/directory-of-services.js" /* webpackChunkName: "component---src-pages-directory-of-services-js" */),
  "component---src-pages-donation-form-js": () => import("./../../../src/pages/_donation-form.js" /* webpackChunkName: "component---src-pages-donation-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-shortlist-js": () => import("./../../../src/pages/_my-shortlist.js" /* webpackChunkName: "component---src-pages-my-shortlist-js" */),
  "component---src-pages-paperfom-payment-failed-js": () => import("./../../../src/pages/_paperfom-payment-failed.js" /* webpackChunkName: "component---src-pages-paperfom-payment-failed-js" */),
  "component---src-pages-paperform-payment-processing-js": () => import("./../../../src/pages/_paperform-payment-processing.js" /* webpackChunkName: "component---src-pages-paperform-payment-processing-js" */),
  "component---src-pages-payment-failed-js": () => import("./../../../src/pages/_payment-failed.js" /* webpackChunkName: "component---src-pages-payment-failed-js" */),
  "component---src-pages-payment-processing-js": () => import("./../../../src/pages/_payment-processing.js" /* webpackChunkName: "component---src-pages-payment-processing-js" */),
  "component---src-pages-power-to-protect-search-js": () => import("./../../../src/pages/power-to-protect/search.js" /* webpackChunkName: "component---src-pages-power-to-protect-search-js" */),
  "component---src-pages-power-to-protect-whanau-maori-js": () => import("./../../../src/pages/power-to-protect/whanau-maori.js" /* webpackChunkName: "component---src-pages-power-to-protect-whanau-maori-js" */),
  "component---src-pages-preview-custom-page-js": () => import("./../../../src/pages/preview/custom-page.js" /* webpackChunkName: "component---src-pages-preview-custom-page-js" */),
  "component---src-pages-preview-directory-services-js": () => import("./../../../src/pages/preview/directory-services.js" /* webpackChunkName: "component---src-pages-preview-directory-services-js" */),
  "component---src-pages-preview-donation-page-js": () => import("./../../../src/pages/preview/donation-page.js" /* webpackChunkName: "component---src-pages-preview-donation-page-js" */),
  "component---src-pages-preview-embedded-js": () => import("./../../../src/pages/preview/embedded.js" /* webpackChunkName: "component---src-pages-preview-embedded-js" */),
  "component---src-pages-preview-generic-js": () => import("./../../../src/pages/preview/generic.js" /* webpackChunkName: "component---src-pages-preview-generic-js" */),
  "component---src-pages-preview-group-js": () => import("./../../../src/pages/preview/group.js" /* webpackChunkName: "component---src-pages-preview-group-js" */),
  "component---src-pages-preview-guideline-js": () => import("./../../../src/pages/preview/guideline.js" /* webpackChunkName: "component---src-pages-preview-guideline-js" */),
  "component---src-pages-preview-power-to-protect-directory-services-js": () => import("./../../../src/pages/preview/power-to-protect/directory-services.js" /* webpackChunkName: "component---src-pages-preview-power-to-protect-directory-services-js" */),
  "component---src-pages-preview-power-to-protect-generic-js": () => import("./../../../src/pages/preview/power-to-protect/generic.js" /* webpackChunkName: "component---src-pages-preview-power-to-protect-generic-js" */),
  "component---src-pages-preview-power-to-protect-group-js": () => import("./../../../src/pages/preview/power-to-protect/group.js" /* webpackChunkName: "component---src-pages-preview-power-to-protect-group-js" */),
  "component---src-pages-preview-power-to-protect-widgets-js": () => import("./../../../src/pages/preview/power-to-protect/widgets.js" /* webpackChunkName: "component---src-pages-preview-power-to-protect-widgets-js" */),
  "component---src-pages-preview-story-js": () => import("./../../../src/pages/preview/story.js" /* webpackChunkName: "component---src-pages-preview-story-js" */),
  "component---src-pages-preview-widgets-js": () => import("./../../../src/pages/preview/widgets.js" /* webpackChunkName: "component---src-pages-preview-widgets-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-templates-calculator-page-template-js": () => import("./../../../src/templates/CalculatorPageTemplate.js" /* webpackChunkName: "component---src-templates-calculator-page-template-js" */),
  "component---src-templates-custom-page-template-js": () => import("./../../../src/templates/CustomPageTemplate.js" /* webpackChunkName: "component---src-templates-custom-page-template-js" */),
  "component---src-templates-directory-of-services-template-js": () => import("./../../../src/templates/DirectoryOfServicesTemplate.js" /* webpackChunkName: "component---src-templates-directory-of-services-template-js" */),
  "component---src-templates-directory-of-services-template-ptp-js": () => import("./../../../src/templates/DirectoryOfServicesTemplatePTP.js" /* webpackChunkName: "component---src-templates-directory-of-services-template-ptp-js" */),
  "component---src-templates-generic-page-template-js": () => import("./../../../src/templates/GenericPageTemplate.js" /* webpackChunkName: "component---src-templates-generic-page-template-js" */),
  "component---src-templates-generic-page-template-ptp-js": () => import("./../../../src/templates/GenericPageTemplatePTP.js" /* webpackChunkName: "component---src-templates-generic-page-template-ptp-js" */),
  "component---src-templates-guideline-template-js": () => import("./../../../src/templates/GuidelineTemplate.js" /* webpackChunkName: "component---src-templates-guideline-template-js" */),
  "component---src-templates-health-point-service-template-js": () => import("./../../../src/templates/HealthPointServiceTemplate.js" /* webpackChunkName: "component---src-templates-health-point-service-template-js" */),
  "component---src-templates-pages-donation-template-js": () => import("./../../../src/templates/PagesDonationTemplate.js" /* webpackChunkName: "component---src-templates-pages-donation-template-js" */),
  "component---src-templates-pages-group-template-js": () => import("./../../../src/templates/PagesGroupTemplate.js" /* webpackChunkName: "component---src-templates-pages-group-template-js" */),
  "component---src-templates-pages-group-template-ptp-js": () => import("./../../../src/templates/PagesGroupTemplatePTP.js" /* webpackChunkName: "component---src-templates-pages-group-template-ptp-js" */),
  "component---src-templates-widgets-page-template-js": () => import("./../../../src/templates/WidgetsPageTemplate.js" /* webpackChunkName: "component---src-templates-widgets-page-template-js" */),
  "component---src-templates-widgets-page-template-ptp-js": () => import("./../../../src/templates/WidgetsPageTemplatePTP.js" /* webpackChunkName: "component---src-templates-widgets-page-template-ptp-js" */)
}

